import React from "react";
import { usePermission } from "hook";
interface PermissionCheckProps {
  requiredRoles: string[];
}

const PermissionCheck: React.FC<PermissionCheckProps> = ({
  requiredRoles,
  children
}) => {
  const hasPermission = usePermission(requiredRoles);
  if (hasPermission) {
    return <>{children}</>;
  }

  return <></>;
};

export default PermissionCheck;
