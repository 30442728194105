import { useContext } from "react";
import { globalContext } from "context/global";

const usePermission = (requiredRoles: string[]): boolean => {
  const { roles } = useContext(globalContext);
  if (!roles) return false;
  return requiredRoles.some((requiredRole) => roles?.includes(requiredRole));
};

export default usePermission;
