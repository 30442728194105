import React, { useContext, useState, useEffect, useRef } from "react";
import { Formik, Form, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SignaturePad from "react-signature-canvas";
import dayjs from "dayjs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TransactionDTO } from "type";
import { globalContext } from "context/global";
import { getTransactionById, updateTransaction } from "service/api";
import { useStyles, SaveButton, CssTextField } from "./detail.style";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import MyCheckbox from "common/MyCheckbox";
import { SET_SNACKBAR_OPEN, useSnackbarDispatch } from "context/snackbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Input } from "@material-ui/core";
import Comment from "./Comment";
import Warranty from "./Warranty";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//@ts-expect-error because
import Barcode from "react-barcode";

const Detail: React.FC = React.memo(() => {
  const {
    employees,
    inspections: globalInspections,
    currentStoreId,
    stores,
  } = useContext(globalContext);
  const classes = useStyles();
  const { state } = useLocation<{
    transaction: TransactionDTO;
    from: string;
  }>();
  const { id } = useParams<{ id: string }>();
  const [transaction, setTransaction] = useState<TransactionDTO | null>(null);
  const dispatch = useSnackbarDispatch();
  const history = useHistory();
  const [showTerms, setShowTerms] = useState<boolean>(true);
  const currentStore = stores.find((s) => s.id === currentStoreId);
  const [isInspectable, setIsInspectable] = useState<boolean>(
    transaction?.isInspectable || true
  );
  const [pickupTime, setPickupTime] = useState<string>(
    transaction?.pickupTime || ""
  );
  const [warrantyPeriodList] = useState<string[]>([
    "180 days",
    "90 days",
    "30 days",
  ]);
  const [warrantyPeriod, setWarrantyPeriod] = useState<string>(
    transaction?.warrantyPeriod || ""
  );
  const [isSoaking, setIsSoaking] = useState<boolean>(
    transaction?.isSoaking || false
  );
  const [confSignature, setConfSignature] = useState<string>("");
  const [confSignatureCopy, setConfSignatureCopy] = useState<string>("");
  const sigCanvas = useRef({}) as React.RefObject<SignaturePad>;
  console.log("state.from", state.from);
  useEffect(() => {
    (async () => {
      if (state) {
        setTransaction(state.transaction);
        setPickupTime(state.transaction.pickupTime);
        setWarrantyPeriod(state.transaction.warrantyPeriod);
        setIsInspectable(state.transaction.isInspectable);
        setConfSignature(state.transaction.confSignature);
        setConfSignatureCopy(state.transaction.confSignature);
      } else {
        const { data } = await getTransactionById(id);
        data &&
          setTransaction(data) &&
          setPickupTime(data.pickupTime) &&
          setWarrantyPeriod(data.warrantyPeriod) &&
          setIsInspectable(data.isInspectable) &&
          setConfSignature(data.confSignature) &&
          setConfSignatureCopy(data.confSignature);
      }
    })();
  }, [id, state]);
  useEffect(() => {
    (async () => {
      if (isSoaking) {
        setWarrantyPeriod("30 Days");
      }
    })();
  }, [isSoaking]);
  //@ts-expect-error because
  const handleDateChange = (date) => {
    const selectedDate = dayjs.utc(date).local().format("YYYY/MM/DD");
    setPickupTime(selectedDate);
  };
  if (!transaction) return null;

  const {
    customerName,
    contact,
    device,
    color,
    imei,
    issue,
    condition,
    authSignature,
    resolution,
    initInspections,
    finalInspections,
    createdBy,
    createdDate,
    deposit,
    battery,
    passcode,
    email,
    products,
    reference,
    additionInfo,
    membership,
    notification,
    batteryHealth,
    repairedBy,
  } = transaction;

  return (
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={{
          customerName,
          contact,
          device,
          color,
          imei,
          issue,
          condition,
          resolution,
          initInspections,
          authSignature,
          finalInspections:
            finalInspections.length > 0
              ? finalInspections
              : [
                  "SENSOR",
                  "EAR_SPEAKER",
                  "WIFI",
                  "MICROPHONE",
                  "ID",
                  "CHARGING",
                  "LIQUID",
                  "VOLUME",
                  "HOME",
                  "LCD",
                  "LOUD_SPEAKER",
                  "CAMERA",
                ],
          createdById: createdBy.id,
          createdDate,
          battery,
          passcode,
          email,
          products,
          deposit,
          reference,
          additionInfo,
          membership,
          notification,
          batteryHealth,
          repairedBy,
        }}
        onSubmit={async ({ createdById, ...rest }, actions) => {
          const results = await updateTransaction({
            ...rest,
            storeId: currentStoreId ?? "",
            createdById,
            id: transaction.id,
            pickupTime,
            warrantyPeriod,
            isSoaking,
            isInspectable,
            confSignature,
          });
          if (results.error) {
            dispatch({
              type: SET_SNACKBAR_OPEN,
              variant: "error",
              message: results.error,
            });
          } else {
            dispatch({
              type: SET_SNACKBAR_OPEN,
              variant: "success",
              message: "Updated Successfully!",
            });
          }

          actions.setSubmitting(false);
          actions.resetForm();
          history.replace("/collection");
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          isSubmitting,
          setFieldValue,
        }) => {
          const total = values.products
            .map((item) => parseFloat(item.price))
            .reduce((prev, next) => prev + (next || 0), 0)
            .toFixed(2);
          const currentMemberBenefits = (parseFloat(total) * 0.05).toFixed(2);
          return (
            <>
              {showTerms ? (
                <Form className={classes.formWrapper}>
                  <Grid
                    container
                    direction="column"
                    classes={{ root: classes.wrapper }}
                  >
                    <Grid container={true} direction="row">
                      <Grid xs={6} item>
                        <img className={classes.logo} src="/logo.png" alt="" />
                      </Grid>
                      <Grid
                        xs={6}
                        container={true}
                        direction="row"
                        justify="flex-end"
                      >
                        <div className={classes.tewWrapper}>
                          <div className={classes.tewItem}>
                            <span className={classes.tewItemTitle}>T</span>
                            <span className={classes.tewItemText}>
                              {currentStore?.contact}
                            </span>
                          </div>
                          <div className={classes.tewItem}>
                            <span className={classes.tewItemTitle}>E</span>
                            <span className={classes.tewItemText}>
                              {currentStore?.email}
                            </span>
                          </div>
                          <div className={classes.tewItem}>
                            <span className={classes.tewItemTitle}>W</span>
                            <span className={classes.tewItemText}>
                              elephone.com.au
                            </span>
                          </div>
                        </div>
                        <div className={classes.watergardensWrapper}>
                          <span className={classes.watergardensTitle}>
                            Elephone{" "}
                            {(currentStore?.name ?? "")
                              .charAt(0)
                              .toUpperCase() +
                              (currentStore?.name ?? "").slice(1)}
                          </span>
                          <span className={classes.watergardensText}>
                            {currentStore?.address}
                            <br />
                            {`${currentStore?.suburb} ${currentStore?.state} ${currentStore?.postcode}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container={true}
                      direction="row"
                      classes={{ root: classes.bottomWrapper }}
                    >
                      <Grid xs={6} container={true} direction="column">
                        <span className={classes.workAuthorisationForm}>
                          Work Authorisation Form
                        </span>
                        <div className={classes.workAuthorisationItem}>
                          <span className={classes.workAuthorisationTitle}>
                            Reference No.：
                          </span>
                          <span className={classes.workAuthorisationText}>
                            {transaction.reference}
                          </span>
                        </div>
                        <div className={classes.workAuthorisationItem}>
                          <span className={classes.workAuthorisationTitle}>
                            Date：
                          </span>
                          <span className={classes.workAuthorisationText}>
                            {dayjs
                              .utc(values.createdDate.substring(0, 23))
                              .local()
                              .format("D MMM YYYY")}
                          </span>
                        </div>
                        <div className={classes.workAuthorisationItem}>
                          <span className={classes.workAuthorisationTitle}>
                            Pickup Date：
                          </span>
                          <div className={classes.pickupTimeWrapper}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="none"
                                id="date-picker-dialog"
                                format="yyyy/MM/dd"
                                value={pickupTime}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                        <div className={classes.workAuthorisationItem}>
                          <span
                            className={`${classes.workAuthorisationTitle} ${classes.warrantyPeriodTitle}`}
                          >
                            Warranty Period：
                          </span>
                          {!isSoaking ? (
                            <div className={classes.warrantyPeriodWrapper}>
                              <Autocomplete
                                id="combo-box-demo"
                                options={warrantyPeriodList}
                                value={warrantyPeriod}
                                getOptionLabel={(option: string) => option}
                                className={classes.select}
                                //@ts-expect-error because
                                onChange={(event, newValue: string | "") => {
                                  setWarrantyPeriod(newValue);
                                }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    className={classes.margin}
                                    label="Warranty Period"
                                    variant="outlined"
                                    id="custom-css-outlined-input"
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <span className={classes.warrantyPeriodText}>
                              {warrantyPeriod}
                            </span>
                          )}
                        </div>
                        <div
                          className={`${classes.workAuthorisationItem} ${classes.warrantyExpiryDateWrapper}`}
                        >
                          <span className={classes.workAuthorisationTitle}>
                            Warranty Expiry Date：
                          </span>
                          <span className={classes.workAuthorisationTitle}>
                            {transaction.warrantyExpiryDate}
                          </span>
                        </div>
                      </Grid>
                      <Grid
                        xs={6}
                        container={true}
                        direction="row"
                        justify="flex-end"
                      >
                        <Barcode
                          value={transaction.reference}
                          width={1.5}
                          height={40}
                          fontSize={14}
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.line} />
                    <Grid
                      xs={12}
                      container={true}
                      direction="row"
                      justify="space-between"
                    >
                      <Grid classes={{ root: classes.muiGridRoot }} item>
                        <span className={classes.infoTitle}>
                          Device Information
                        </span>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>
                            Device Model
                          </span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="device"
                            placeholder="Device Model"
                            value={values.device}
                            onChange={handleChange}
                          />
                        </div>
                        <Grid
                          container={true}
                          direction="row"
                          justify="space-between"
                        >
                          <Grid
                            xs={5}
                            container={true}
                            direction="column"
                            classes={{ root: classes.infoItemRoot }}
                          >
                            <span className={classes.infoLabel}>Colour</span>
                            <Input
                              classes={{
                                root: classes.inputRoot,
                                input: classes.input,
                              }}
                              disableUnderline={true}
                              name="color"
                              placeholder="Colour"
                              value={values.color}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid
                            xs={5}
                            container={true}
                            direction="column"
                            classes={{ root: classes.infoItemRoot }}
                          >
                            <span className={classes.infoLabel}>Passcode</span>
                            <Input
                              classes={{
                                root: classes.inputRoot,
                                input: classes.input,
                              }}
                              disableUnderline={true}
                              name="passcode"
                              placeholder="Passcode"
                              value={values.passcode}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>IMEI</span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="imei"
                            placeholder="IMEI"
                            value={values.imei}
                            onChange={handleChange}
                          />
                        </div>
                        <span
                          className={`${classes.infoTitle} ${classes.marginTop25}`}
                        >
                          Repair Details
                        </span>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>
                            Device Issue
                          </span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="issue"
                            placeholder="Device Issue"
                            value={values.issue}
                            onChange={handleChange}
                          />
                        </div>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>
                            Proposed Solution
                          </span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="resolution"
                            placeholder="Proposed Solution"
                            value={values.resolution}
                            onChange={handleChange}
                          />
                        </div>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>Comments</span>
                          <Input
                            classes={{
                              input: classes.textInput,
                            }}
                            disableUnderline={true}
                            multiline={true}
                            rows="4"
                            name="additionInfo"
                            placeholder="Comments"
                            value={values.additionInfo}
                            onChange={handleChange}
                          />
                        </div>
                        <div className={classes.repairEstimateWrapper}>
                          <span className={classes.infoTitle}>
                            Repair Estimate
                          </span>
                          <div
                            onClick={() => {
                              const newItems = [...values.products];
                              newItems.push({
                                description: "",
                                number: "",
                                price: "",
                              });
                              setFieldValue("products", newItems);
                            }}
                          >
                            <span className={classes.addIcon}>+</span>
                          </div>
                        </div>
                        <span className={classes.repairEstimateDesc}>
                          *If device requires further inspection, a $30
                          diagnostic fee will apply. Diagnostic fee is waived
                          when repair is booked with Elephone.
                        </span>
                        <div className={classes.repairEstimateTitleWrapper}>
                          <span
                            className={`${classes.repairEstimateTitle} ${classes.width6}`}
                          >
                            No.
                          </span>
                          <span
                            className={`${classes.repairEstimateTitle} ${classes.width58}`}
                          >
                            Description
                          </span>
                          <span
                            className={`${classes.repairEstimateTitle} ${classes.width32}`}
                          >
                            Amount
                          </span>
                        </div>
                        <FieldArray
                          name="products"
                          render={(arrayHelpers) => (
                            <>
                              {values.products.map((item, index) => {
                                return (
                                  <div
                                    className={classes.repairEstimateItem}
                                    key={index}
                                  >
                                    <span
                                      className={`${classes.repairEstimateNo} ${classes.width6}`}
                                    >
                                      {index + 1}
                                    </span>
                                    <Input
                                      classes={{ root: classes.width58 }}
                                      id="description"
                                      name={`products[${index}].description`}
                                      value={item.description}
                                      onChange={handleChange}
                                    />
                                    <div
                                      className={`${classes.amountWrapper} ${classes.width32}`}
                                    >
                                      <span className={classes.moneyIcon}>
                                        $
                                      </span>
                                      <Input
                                        classes={{
                                          input: classes.amountInput,
                                        }}
                                        id="price"
                                        name={`products[${index}].price`}
                                        value={item.price}
                                        onChange={handleChange}
                                      />
                                      <div
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        className={classes.removeIconWrapper}
                                      >
                                        <div
                                          className={classes.removeIconRadio}
                                        >
                                          <span className={classes.removeIcon}>
                                            -
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        />
                        <div className={classes.discountWrapper}>
                          <div className={classes.discountItem}>
                            <span className={classes.discountLabel}>
                              Member benefits
                            </span>
                            <div className={classes.discountContentWrapper}>
                              <span className={classes.totalIcon}>$</span>
                              <Input
                                classes={{
                                  input: classes.discountContent,
                                }}
                                onChange={handleChange}
                                value={
                                  values.membership
                                    ? currentMemberBenefits
                                    : "0.00"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={classes.depositWrapper}>
                          <div className={classes.depositItem}>
                            <span className={classes.totalLabel}>Deposit</span>
                            <div className={classes.totalContentWrapper}>
                              <span className={classes.totalIcon}>$</span>
                              <Input
                                classes={{
                                  input: classes.totalContent,
                                }}
                                name="deposit"
                                onChange={handleChange}
                                value={values.deposit}
                              />
                            </div>
                          </div>
                          <div className={classes.depositItem}>
                            <span className={classes.totalLabel}>Balance</span>
                            <div className={classes.totalContentWrapper}>
                              <span className={classes.totalIcon}>$</span>
                              <Input
                                classes={{
                                  input: classes.totalContent,
                                }}
                                name="balance"
                                onChange={handleChange}
                                value={(
                                  parseFloat(total) -
                                  (values.membership
                                    ? parseFloat(currentMemberBenefits)
                                    : parseFloat("0.00")) -
                                  parseFloat(values.deposit || "0.00")
                                ).toFixed(2)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={classes.totalWrapper}>
                          <div className={classes.totalItem}>
                            <span className={classes.totalLabel}>Total</span>
                            <div className={classes.totalContentWrapper}>
                              <span className={classes.totalIcon}>$</span>
                              <Input
                                classes={{
                                  input: classes.totalContent,
                                }}
                                onChange={handleChange}
                                value={(
                                  parseFloat(total) -
                                  (values.membership
                                    ? parseFloat(currentMemberBenefits)
                                    : parseFloat("0.00"))
                                ).toFixed(2)}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        classes={{ root: classes.muiGridRoot }}
                        container={true}
                      >
                        <div className={classes.inspectionTitleWrapper}>
                          <span className={classes.inspectionTitle}>
                            Inspection
                          </span>
                          <div className={classes.applicableWrapper}>
                            <FormControlLabel
                              classes={{
                                label: classes.applicable,
                              }}
                              control={
                                <Checkbox
                                  checked={isInspectable}
                                  onChange={() => {
                                    if (isInspectable) {
                                      setFieldValue(
                                        `${
                                          state.from === "dashboard"
                                            ? "initInspections"
                                            : "finalInspections"
                                        }`,
                                        []
                                      );
                                    }
                                    setIsInspectable(!isInspectable);
                                  }}
                                  color="primary"
                                />
                              }
                              label="Applicable?"
                            />
                          </div>
                        </div>
                        <span className={classes.inspectionContent}>
                          *Inspection: If the phone/device will not turn on or
                          the display is too damaged prior to service, Elephone
                          will not be liable for any other issues found other
                          than the serviced parts and services. All other issues
                          with the phone/device will be at the customer’s own
                          cost.
                        </span>
                        <div className={classes.problemTypeWrapper}>
                          <div className={classes.membershipItem}>
                            <FormControlLabel
                              classes={{
                                label: classes.soakingCheckbox,
                              }}
                              control={
                                <Checkbox
                                  checked={isSoaking}
                                  name="isSoaking"
                                  color="primary"
                                  onChange={(event) => {
                                    setIsSoaking(event.target.checked);
                                  }}
                                />
                              }
                              label="Has this device been previously liquid damaged?"
                            />
                          </div>
                          {globalInspections.map((ele) => (
                            <Grid item xs={6} key={ele.key}>
                              <MyCheckbox
                                name={
                                  state.from === "dashboard"
                                    ? "initInspections"
                                    : "initInspections"
                                }
                                type="checkbox"
                                value={ele.key}
                                label={ele.displayName}
                                disabled={!isInspectable}
                              />
                            </Grid>
                          ))}
                          <div className={classes.batteryHealthWrapper}>
                            <span className={classes.batteryHealthLabel}>
                              Battery Health %：
                            </span>
                            <Input
                              classes={{
                                input: classes.batteryHealthInput,
                              }}
                              name="batteryHealth"
                              onChange={handleChange}
                              value={values.batteryHealth}
                            />
                          </div>
                        </div>
                        <span
                          className={`${classes.infoTitle} ${classes.marginTop25} ${classes.marginTop25}`}
                        >
                          Customer Details
                        </span>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>
                            Name<span className={classes.requestColor}>*</span>
                          </span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="customerName"
                            placeholder="Name"
                            defaultValue={values.customerName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>
                            Email<span className={classes.requestColor}>*</span>
                          </span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="email"
                            placeholder="Email"
                            defaultValue={values.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className={classes.infoItem}>
                          <span className={classes.infoLabel}>
                            Phone Number
                            <span className={classes.requestColor}>*</span>
                          </span>
                          <Input
                            classes={{
                              root: classes.inputRoot,
                              input: classes.input,
                            }}
                            disableUnderline={true}
                            name="contact"
                            placeholder="Phone Number"
                            defaultValue={values.contact}
                            onChange={handleChange}
                          />
                        </div>
                        <span className={classes.agreementTitle}>
                          Agreement
                        </span>
                        <span className={classes.agreementContent}>
                          I accept that Elephone is not responsible for any
                          loss, corruption or breach of the data on my product
                          during service. I assume the risk that the data on my
                          product may be lost, corrupted or compromised during
                          service. By signing below, I agree that the repair{" "}
                          <span
                            className={classes.agreementContentColor}
                            onClick={() => {
                              setShowTerms(false);
                            }}
                          >
                            Terms and Conditions
                          </span>{" "}
                          will apply to the service of the product identified
                          above; It is my responsibility to make backup copy of
                          my data before bringing my product to Elephone for
                          service. Warranty is valid for any parts used for
                          repair. Does not cover any physical or water damage. I
                          hereby authorise Elephone to proceed with the service.
                        </span>
                        <span className={classes.membershipTitle}>
                          Membership (Optional)
                        </span>
                        <div className={classes.membershipItem}>
                          <FormControlLabel
                            classes={{
                              label: classes.applicable,
                            }}
                            control={
                              <Checkbox
                                checked={values.membership}
                                name="membership"
                                color="primary"
                                onChange={handleChange}
                              />
                            }
                            label="I would like to be an Elephone Member to receive, Loyalty Rewards & 5% loyalty discount."
                          />
                        </div>
                        <div className={classes.membershipItem}>
                          <FormControlLabel
                            classes={{
                              label: classes.applicable,
                            }}
                            control={
                              <Checkbox
                                checked={values.notification}
                                name="notification"
                                color="primary"
                                onChange={handleChange}
                              />
                            }
                            label="I would like to receive a monthly Elephone Newsletter for tech news, tips and specials as well as a $5 voucher for my next purchase."
                          />
                        </div>
                        <div className={classes.signatureTitleWrapper}>
                          <span className={classes.signatureTitle}>
                            Signature
                            <span className={classes.requestColor}>*</span>
                          </span>
                          {state.from !== "dashboard" && (
                            <div
                              className={classes.clearButton}
                              onClick={() => {
                                if (confSignature !== "") {
                                  setConfSignature("");
                                } else {
                                  sigCanvas.current?.clear();
                                }
                              }}
                            >
                              Clear
                            </div>
                          )}
                        </div>
                        <div className={classes.signatureWrapper}>
                          {(confSignature ?? "").length > 0 &&
                          confSignature === confSignatureCopy ? (
                            <img
                              src={confSignature}
                              alt="my signature"
                              className={classes.signatureCanvas}
                            />
                          ) : state.from === "dashboard" ? (
                            <img
                              src={authSignature}
                              alt="my signature"
                              className={classes.signatureCanvas}
                            />
                          ) : (
                            <SignaturePad
                              ref={sigCanvas}
                              clearOnResize={false}
                              canvasProps={{
                                className: classes.signatureCanvas,
                              }}
                              onEnd={() => {
                                setConfSignature(
                                  sigCanvas.current
                                    ?.getCanvas()
                                    .toDataURL("image/png") ?? ""
                                );
                              }}
                            />
                          )}
                        </div>
                        <Grid container>
                          <div className={classes.createdByWrapper}>
                            <span className={classes.label}>Created by</span>
                            <Select
                              value={values.createdById}
                              onChange={handleChange}
                              fullWidth
                              className={classes.createByInput}
                              style={{ marginTop: 10 }}
                              inputProps={{
                                name: "createdById",
                                id: "createdById",
                              }}
                            >
                              {employees.map((employee) => (
                                <MenuItem key={employee.id} value={employee.id}>
                                  {employee.firstName} {employee.lastName}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>
                        {repairedBy != null && (
                          <Grid container>
                            <div className={classes.repairedByWrapper}>
                              <span className={classes.repairedByLabel}>
                                Repaired by: {repairedBy}
                              </span>
                            </div>
                          </Grid>
                        )}
                        <div className={classes.saveButtonWrapper}>
                          <SaveButton
                            variant="contained"
                            color="primary"
                            disableRipple
                            className={classes.buttonMargin}
                            disabled={isSubmitting}
                            type="submit"
                          >
                            Save
                          </SaveButton>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              ) : (
                <div className={classes.wrapper}>
                  <Paper
                    className={classes.paper}
                    classes={{ root: classes.paperRoot }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                      }}
                    >
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowTerms(!showTerms);
                        }}
                        style={{ display: "block", marginLeft: "-20px " }}
                      >
                        <ArrowBackIcon />
                      </Button>
                      Repair Terms and Conditions These are the Terms and
                      Conditions governing the repair of your product by
                      L’Hopital Pty Ltd trading as Elephone, ABN 91614929127.
                      <br /> 1. Elephone will service your product as described
                      and for the charges shown on the Work Authorisation.{" "}
                      <br /> 2. Unless your product is repaired under warranty
                      or extended service contract without charge, you will pay
                      Elephone the amount shown on the reverse side. Your
                      payment is due when the product is returned to you. Unless
                      specified otherwise, the estimated amount shown on the
                      reverse side includes all parts, labour, transportation
                      required for the repair of the product. Elephone may hold
                      you responsible for the diagnostic fee on the reverse
                      side. if Elephone inspects your product, provide an
                      estimate for you, and you do not authorise Elephone to
                      undertake the repairs for the estimated charges. If
                      Elephone determines, while inspecting your product, that
                      repairs are needed due to failures of parts that are
                      neither supplied by Elephone nor Elephone branded — or are
                      needed due to damage caused by abuse, misuse or
                      misapplication — Elephone reserves the right to return the
                      product to you without repairing it, and will hold you
                      responsible for the diagnostic fee on the reverse side.{" "}
                      <br /> 3. Labour charges may also apply for any
                      cancellation or reversals of repairs after confirmation to
                      proceed with repair works. If the requested repairs
                      require labour and/or parts not specified on the reverse
                      side, Elephone may seek your approval of a revised
                      estimate. If you do not agree that Elephone may revise the
                      charges, Elephone may return your product and hold you
                      responsible for the diagnostic fee shown on the reverse
                      side.
                      <br /> 4. Elephone does not provide any warranty for any
                      spare parts supplied by the third parties (dealer or
                      customer).Liquid/water ingress will have only 30 days
                      warranty, no refund or warranty after 30days from the pick
                      up date. This covers the specific component replaced or
                      work done. There is no warranty for the failure of any
                      additional components at a later stage. Furthermore, due
                      to possible progressive corrosion, company does not accept
                      responsibility for any further faults that may have
                      occurred during the time between the quotation and the
                      commencement of repairs.Phone Repair Service warranty
                      applies when the exact same fault occurs or the replaced
                      component fails within the *warranty period, with the
                      exception of liquid/Water damaged phones / devices.
                      Physical damage is not covered by warranty.Warranty will
                      be ceased immediately if the phone/device is repaired or
                      altered by any third party without the consent of our
                      Company. If repairing your product under your part’s
                      warranty or extended service contract, Elephone may use
                      new, used or reconditioned parts, as permitted by these
                      terms. If repairing parts outside of warranty or extended
                      service contract. Elephone may use new, used or
                      reconditioned parts. If Elephone replaces a part, then
                      unless otherwise agreed, title in the replaced part will
                      pass to Elephone as its property, and the replacement part
                      will become your property. Replaced parts are generally
                      repairable and are exchanged or repaired by Elephone for
                      value. If you and Elephone agree for Elephone to return a
                      replaced part to you, you agree to pay Elephone the cost
                      of the replacement item in addition to any other charges
                      for the repair service.
                      <br /> 5. All supplied parts are compatible/equivalent to
                      the original parts, your original manufacturer’s warranty
                      will be void for any repair service. If Elephone repairs
                      your product under warranty or extended service contract,
                      the repair will be concerned solely by the terms of
                      warranty or extended service contract and applicable
                      provisions of law, if your product is repaired outside of
                      warranty, Elephone warrants (1) that the repairs will be
                      performed in a competent and workmanlike manner and (2)
                      that all parts used to repair your product will be free
                      from defects in materials and workmanship for a period up
                      to {currentStore?.warranty} days, unless otherwise
                      specified by Elephone. Information on your product’s parts
                      warranty maybe obtained by contacting Elephone. The
                      warranty on parts is an express limited warranty. Goods
                      presented for repair may be replaced by refurbished goods
                      of the same type rather than being repaired. Refurbished
                      parts may be used to repair the goods. If a defect exists
                      in a replacement part during the part’s warranty period.
                      at its option, Elephone will (1) repair the part using
                      new, used or reconditioned replacement parts, (2) replace
                      the part with a new, used or reconditioned equivalent
                      part, or (3) refund the fair market value of the part, as
                      determined by Elephone. To the extent permitted by law
                      this warranty and remedy are exclusive and in lieu of all
                      other warranties, remedies and conditions, whether oral or
                      written, express or implied, with respect to the repair
                      parts, To the extent permitted by law Elephone
                      specifically disclaims any and all implied warranties with
                      respect to such parts, including, without limitation,
                      warranties of merchantability and fitness for a particular
                      purpose. If Elephone cannot lawfully disclaim implied
                      warranties under this limited warranty, all such
                      warranties, including warranties of merchantability and
                      fitness for a particular purpose, are limited in duration
                      to the duration of the express limited warranty. a.
                      Certain legislation, including the competition and
                      consumer act 2010, may imply warranties or conditions or
                      impose obligations upon Elephone, which cannot be
                      excluded, restricted or modified, or cannot be excluded,
                      restricted or modified except to a limited extent. These
                      Terms and Conditions must be read subject to these
                      statutory provisions. If these statutory provisions apply,
                      to the extent to which Elephone is entitled to do so,
                      Elephone limits its liability in respect of any claim
                      under those provisions to at Elephone’s option (1) the
                      supplying of the services again or (2) the payment of the
                      cost of having the services supplied again. <br /> 6. If
                      the phone/device cannot turn on or no display on the
                      phone/device, our company will not have any liabilities to
                      any other parts apart from the replaced parts and
                      services. All other problems which occurs to the
                      phone/device will be at customer&rsquo;s own cost. All
                      assessment is based on final assessment by technician, our
                      company will reserve the right to revoke the assessment or
                      verify the price. Further notice will be given to
                      customers. No cosmetics claim after the repair services.
                      Elephone does not carry any responsibility for such claim.
                      If any product should be damaged or lost while in
                      Elephone’s custody, to the extent permitted by law
                      Elephone’s liability will be limited to the cost of repair
                      or replacement of the affected product. Subject to the
                      previous sentence, Elephone’s entire liability for your
                      damages from any cause whatsoever with respect to the
                      repair of your product, whether due to Elephone’s
                      negligence or to any other reason, is limited to the
                      amounts that you pay for repair services. To the extent
                      permitted by law, your only remedy under this repair
                      agreement in respect of any matter not addressed by the
                      previous sentences is to seek recovery of damages against
                      Elephonoe in an amount not exceeding what you have paid
                      for repair services. To the extent permitted by law,
                      Elephone has no liability whatsoever for indirect,
                      special, exemplary or consequential damages, lost profits
                      or revenue. To the extent permitted by law, Elephone also
                      has no liability for loss of or damage to data or software
                      applications. To the extent permitted by law, Elephone is
                      not responsible for any failures pr delays in providing
                      services that are due to events outside Elephone’s
                      reasonable control. Repair of your goods may result in
                      loss of data. To the extent permitted by law, Elephone is
                      not liable for any defects or damage to any software or
                      data stored, residing or recorded in your product, nor for
                      any loss, corruption or breach of such data, including any
                      confidential, proprietary or personal information or
                      removable data. By providing your product under these
                      terms and conditions you accept and agree that either (A)
                      you have made a backup copy of your data and removed any
                      confidential, proprietary or personal information and
                      removable media such as usb devices, CDs or PC cards, or
                      (B) you have assumed the risk that suck information or
                      media may be lost, corrupted or compromised during service
                      or repair, you further acknowledge and agree that Elephone
                      cannot guarantee the safety, security or integrity of any
                      data that remains on you product while it is undergoing
                      service or repair.
                      <br /> 7. It is a condition of Elephone accepting your
                      product for repair that the product is subject to disposal
                      of uncollected goods legislation and that legislation
                      confers on Elephone a right of sale exercisable in certain
                      circumstances. If you have not claimed your product and
                      paid all charges due within the minimum period by law
                      after Elephone has provided you with notice that you
                      product is ready for pick up, Elephone will consider you
                      product abandoned and Elephone will be entitled to
                      exercise its right under law to dispose of the product.
                      Elephone will provide all notices to you required by this
                      section or that Elephone is required to provide to you
                      under law in relation to the exercise of Elephone’s right
                      of disposal, by messaging or emailing such notice to the
                      contact you furnished when you authorised the repairs.
                      Elephone may dispose of your product in accordance with
                      law, and specifically. Elephone may sell your product at a
                      private or public sale or by such other legally permitted
                      means without liability to you. Elephone may deduct from
                      the disposal proceeds any amounts owing to Elephone and
                      any remainder will be dealt with in accordance with law.
                      Elephone reserves its statutory and any other lawful liens
                      for unpaid charges.
                      <br /> 8. If repair service involves transferring
                      information or installing software, you represent that you
                      have the legal right to copy the information and agree to
                      the terms of software licence, and you authorise Elephone
                      to transfer the information and accept such terms on your
                      behalf in performing the service.
                      <br /> 9. Warranty for repair is {
                        currentStore?.warranty
                      }{" "}
                      days (except Liquid/water damage service). Warranty can be
                      extended, only if customer purchases the extra warranty
                      (no warranty extension for Liquid/water damage services).
                      <br /> 10. There Terms and Conditions are the only ones
                      that govern Elephone’s repair of your product. Except as
                      otherwise stated in these Terms and Conditions, no other
                      oral or written Terms or Conditions apply. No one has the
                      authority from Elephone to vary any of these Terms and
                      Conditions. Elephone resserves the right to alter the
                      Terms and Conditions without prior notice and make the
                      final decision in the event of any dispute.
                      <br /> 11. You agree and understand that it is necessary
                      for Elephone to collect, process and use your data in
                      order to perform the service and support obligations under
                      these Terms and Conditions. In Elephone, you trust means
                      everything to us. That’s why we respect your privacy and
                      protect it with strict policies that govern how all data
                      is handled.
                    </div>
                  </Paper>
                </div>
              )}
            </>
          );
        }}
      </Formik>
      {showTerms ? (
        <Grid item lg={3} md={12} className={classes.funcWrapper}>
          <Grid container>
            <Grid item md={6} lg={12} xs={6}>
              <Comment transaction={transaction} employees={employees} />
            </Grid>
            <Grid item md={6} lg={12} xs={6}>
              <Warranty
                transaction={transaction}
                setTransaction={setTransaction}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
});

export default Detail;
