import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { TransactionDTO } from "type";
import dayjs from "dayjs";
import { List, ListItem, ListItemText } from "@material-ui/core";

interface MoveHistoryProps {
  open: boolean;
  onClose: () => void;
  transaction: TransactionDTO | null;
}

const MoveHistory: React.FC<MoveHistoryProps> = ({
  transaction,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          <List>
            {transaction?.transactionTransitions
              .sort((a, b) => {
                return +new Date(a.createdDate) - +new Date(b.createdDate);
              })
              .map((path, index) => (
                <ListItem key={path.id}>
                  <ListItemText>
                    {index === 0
                      ? `${path.store.name} Created at Store `
                      : `${
                          transaction.transactionTransitions[index - 1].store
                            .name
                        } Move from Store to ${path.store.name} Store `}
                    at {dayjs(path.createdDate).format("DD/MM/YYYY HH:mm:ss")}
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveHistory;
