import React, { useState, useContext, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useStyles, AddButton } from "./store.style";
import Cell from "./Cell";
import CreateStoreModal from "./Modal";
import { globalContext } from "context/global";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import { useClickOutside, usePermission } from "hook";
import { updateStore, deleteStoreById } from "service/api";
import type { StoreDTO } from "type";
import ActionDialog from "component/Transaction/Transaction/ActionDialog";
import PermissionCheck from "common/PermissionCheck";

const tableKeys = [
  "name",
  "companyName",
  "warranty",
  "email",
  "abn",
  "contact",
  "suburb",
  "address",
];

type StoreStatus = "active" | "inactive";

const Store: React.FC = React.memo(() => {
  const classes = useStyles();
  const hasOwnerPermission = usePermission(["OWNER"]);
  const dispatch = useSnackbarDispatch();
  const { stores, setStores } = useContext(globalContext);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<StoreDTO | undefined>(
    void 0
  );
  const [currentStore] = useState<StoreDTO | undefined>(void 0);
  const [selectedField, setSelectedField] = useState<string>("");
  const [status] = useState<StoreStatus>("active");
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(async () => {
    if (selectedStore) {
      let changed = false;
      setStores((stores) =>
        stores.map((store) => {
          if (store.id === selectedStore.id) {
            //@ts-expect-error because
            if (store[selectedField] !== selectedStore[selectedField]) {
              changed = true;
            }
            return selectedStore;
          }
          return store;
        })
      );
      setSelectedStore(void 0);
      setSelectedField("");
      if (changed) {
        const { error } = await updateStore(selectedStore);

        if (error) {
          dispatch({
            type: SET_SNACKBAR_OPEN,
            variant: "error",
            message: error ?? "Error: can't update employee",
          });
        }
      }
    }
  });

  const isSelected = useCallback(
    (storeId: string, field: string) => {
      if (status === "inactive") return false;
      return storeId === selectedStore?.id && field === selectedField;
    },
    [selectedField, selectedStore, status]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      const { data, error } = await deleteStoreById(id);
      if (data) {
        dispatch({
          type: SET_SNACKBAR_OPEN,
          variant: "success",
          message: "Store deleted.",
        });
        setStores((stores) =>
          stores.map((globalStore) => {
            if (globalStore.id === currentStore?.id ?? "") {
              globalStore.isDeleted = true;
            }
            return globalStore;
          })
        );
      }

      if (error) {
        dispatch({
          type: SET_SNACKBAR_OPEN,
          variant: "error",
          message: error ?? "Error: can't update employee",
        });
      }
    },
    [currentStore, dispatch, setStores]
  );

  return (
    <Grid container className={classes.wrapper}>
      <Grid container alignItems="center" justify="space-between">
        <span className={classes.title}>Staff</span>
        <PermissionCheck requiredRoles={["OWNER"]}>
          <AddButton
            onClick={() => setIsShowModal(true)}
            variant="contained"
            color="primary"
            disableRipple
            className={classes.buttonMargin}
          >
            Add New
          </AddButton>
        </PermissionCheck>
      </Grid>
      <Table size="small" classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>Store</TableCell>
            <TableCell className={classes.headerTitle}>Company Name</TableCell>
            <TableCell className={classes.headerTitle}>Warranty</TableCell>
            <TableCell className={classes.headerTitle}>Email</TableCell>
            <TableCell className={classes.headerTitle}>ABN</TableCell>
            <TableCell className={classes.headerTitle}>Contact</TableCell>
            <TableCell className={classes.headerTitle}>Suburb</TableCell>
            <TableCell className={classes.headerTitle}>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stores
            .filter(
              (store) =>
                store.isDeleted === (status === "active" ? false : true)
            )
            .map((store) => {
              return (
                <TableRow key={store.id}>
                  {tableKeys.map((key) => {
                    return (
                      <TableCell
                        align="left"
                        key={key}
                        className={classes.tableContent}
                      >
                        <Cell
                          ref={ref}
                          isSelected={isSelected(store.id, key)}
                          canSelect={hasOwnerPermission}
                          //@ts-expect-error because
                          inputValue={selectedStore?.[key] ?? ""}
                          //@ts-expect-error because
                          value={store[key]}
                          onChange={(e) => {
                            e.persist();
                            setSelectedStore((store) => {
                              if (!store) return void 0;
                              return {
                                ...store,
                                [key]: e.target.value,
                              };
                            });
                          }}
                          onClick={() => {
                            if (hasOwnerPermission) {
                              setSelectedStore(store);
                              setSelectedField(key);
                            }
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <CreateStoreModal
        isShowModal={isShowModal}
        closeModal={() => setIsShowModal(false)}
      />
      {deleteOpen && (
        <ActionDialog
          open={deleteOpen}
          onClose={() => {
            setDeleteOpen(false);
          }}
          info="Delete this store?"
          handleConfirm={() => handleDelete(currentStore?.id ?? "")}
        />
      )}
    </Grid>
  );
});

export default Store;
