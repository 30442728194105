import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import App from "App";
import ErrorBoundary from "common/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import { muiTheme } from "style/theme";
import { SnackbarProvider } from "context/snackbar";
import { StylesProvider } from "@material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import { GlobalProvider } from "context/global";

import "./index.css";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
  },
});

const Elephone: React.FC = () => {
  return (
    <ErrorBoundary>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <SnackbarProvider>
            <BrowserRouter>
              <GlobalProvider>
                <App />
              </GlobalProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Elephone />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
