import { useEffect, useCallback, useRef, RefObject } from "react";

const useClickOutside = <T extends HTMLElement>(
  handler: (event: MouseEvent | TouchEvent) => void
): RefObject<T> => {
  const ref = useRef<T>(null);

  const memorisedHandler = useCallback(
    (event: MouseEvent | TouchEvent) => handler(event),
    [handler]
  );

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      if (!ref || !ref.current) return;
      if (!ref.current.contains(event.target as Node)) memorisedHandler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, memorisedHandler]);

  return ref;
};

export default useClickOutside;
