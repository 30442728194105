import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: 112,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `114px 148px 112px`,
    width: 623,
    height: 405,
    boxSizing: "border-box",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    boxShadow: "0px 0px 30px 20px rgba(42, 179, 231, 0.1)",
    position: "relative",
  },
  avatar: {
    // margin: theme.spacing(1),
    position: "absolute",
    top: 10,
    width: 267,
    height: 52,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: 24,
    height: 40,
  },
  error: {
    color: "red",
  },
}));
