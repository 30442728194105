import React, { useState, useEffect, useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./SideBar.style";
import PermissionCheck from "common/PermissionCheck";
import { globalContext } from "context/global";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

const SideBar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { currentStoreId } = useContext(globalContext);
  const [selectedIndex, setSelectedIndex] = useState<string>("transaction");
  useEffect(() => {
    setSelectedIndex(location.pathname.slice(1));
  }, [location.pathname]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawer,
      }}
    >
      <div className={classes.content}>
        {currentStoreId && (
          <>
            <ListItem
              classes={{
                root: classes.listItem,
              }}
              button
              selected={selectedIndex === "transaction"}
              onClick={() => {
                setSelectedIndex("transaction");
                history.push("/transaction");
              }}
            >
              <Tooltip title="Transaction">
                <Grid container direction="column" alignItems="center">
                  <i
                    className={`iconfont iconHome ${
                      selectedIndex === "transaction"
                        ? classes.iconfontActive
                        : classes.iconfont
                    }`}
                  ></i>
                  <span
                    className={
                      selectedIndex === "transaction"
                        ? classes.titleActive
                        : classes.title
                    }
                  >
                    Home
                  </span>
                </Grid>
              </Tooltip>
            </ListItem>
            <ListItem
              classes={{
                root: classes.listItem,
              }}
              button
              selected={selectedIndex === "collection"}
              onClick={() => {
                setSelectedIndex("collection");
                history.push("/collection");
              }}
            >
              <Tooltip title="Collection">
                <Grid container direction="column" alignItems="center">
                  <i
                    className={`iconfont iconCollection ${
                      selectedIndex === "collection"
                        ? classes.iconfontActive
                        : classes.iconfont
                    }`}
                  ></i>
                  <span
                    className={
                      selectedIndex === "collection"
                        ? classes.titleActive
                        : classes.title
                    }
                  >
                    Collecting
                  </span>
                </Grid>
              </Tooltip>
            </ListItem>
            <ListItem
              classes={{
                root: classes.listItem,
              }}
              button
              selected={selectedIndex === "history"}
              onClick={() => {
                setSelectedIndex("history");
                history.push("/history");
              }}
            >
              <Tooltip title="History">
                <Grid container direction="column" alignItems="center">
                  <i
                    className={`iconfont iconHistory ${
                      selectedIndex === "history"
                        ? classes.iconfontActive
                        : classes.iconfont
                    }`}
                  ></i>
                  <span
                    className={
                      selectedIndex === "history"
                        ? classes.titleActive
                        : classes.title
                    }
                  >
                    History
                  </span>
                </Grid>
              </Tooltip>
            </ListItem>
          </>
        )}

        <PermissionCheck requiredRoles={["OWNER"]}>
          <ListItem
            classes={{
              root: classes.listItem,
            }}
            button
            selected={selectedIndex === "employee"}
            onClick={() => {
              setSelectedIndex("employee");
              history.push("/employee");
            }}
          >
            <Tooltip title="Staff">
              <Grid container direction="column" alignItems="center">
                <i
                  className={`iconfont iconStaff ${
                    selectedIndex === "employee"
                      ? classes.iconfontActive
                      : classes.iconfont
                  }`}
                ></i>
                <span
                  className={
                    selectedIndex === "employee"
                      ? classes.titleActive
                      : classes.title
                  }
                >
                  Staff
                </span>
              </Grid>
            </Tooltip>
          </ListItem>
        </PermissionCheck>
        <PermissionCheck requiredRoles={["OWNER"]}>
          <ListItem
            classes={{
              root: classes.listItem,
            }}
            button
            selected={selectedIndex === "store"}
            onClick={() => {
              setSelectedIndex("store");
              history.push("/store");
            }}
          >
            <Tooltip title="Stores">
              <Grid container direction="column" alignItems="center">
                <i
                  className={`iconfont iconStore ${
                    selectedIndex === "store"
                      ? classes.iconfontActive
                      : classes.iconfont
                  }`}
                ></i>
                <span
                  className={
                    selectedIndex === "store"
                      ? classes.titleActive
                      : classes.title
                  }
                >
                  Stores
                </span>
              </Grid>
            </Tooltip>
          </ListItem>
        </PermissionCheck>
      </div>
    </Drawer>
  );
};

export default SideBar;
