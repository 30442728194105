import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
  },
  title: {
    marginRight: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuButton: {
    marginRight: 20,
  },
}));
