import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { createWarranty, getTransactionById } from "service/api";
import { TransactionDTO } from "type";
import dayjs from "dayjs";
import { SET_SNACKBAR_OPEN, useSnackbarDispatch } from "context/snackbar";
import { globalContext } from "context/global";

interface WarrantyProps {
  transaction: TransactionDTO;
  setTransaction: React.Dispatch<React.SetStateAction<TransactionDTO | null>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
  },
  commentArea: {
    padding: `0 0 0 ${theme.spacing(2)}px`,
  },
  commentButton: {
    marginTop: theme.spacing(1),
  },
  title: {
    padding: `0 0 0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      margin: 0,
    },
  },
}));

const Warranty: React.FC<WarrantyProps> = ({ transaction, setTransaction }) => {
  const { currentEmployeeId, currentStoreId, employees } =
    useContext(globalContext);
  const classes = useStyles();
  const employee = employees.find((e) => e.id === currentEmployeeId);
  const { warrantyHistories } = transaction;
  const dispatch = useSnackbarDispatch();

  return (
    <Box>
      <Typography variant="h5" className={classes.title} color="primary">
        <strong>Warranty</strong>
      </Typography>
      <List className={classes.root}>
        {warrantyHistories.length
          ? warrantyHistories.map((warranty, index) => (
              <Grid key={warranty.id} container justify="space-between">
                <Grid item xs={12}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={employee?.firstName}
                        src="/static/images/avatar/1.jpg"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {`${employee?.firstName} ${employee?.lastName}`}
                          <Typography
                            component="div"
                            variant="caption"
                            color="textSecondary"
                          >
                            {dayjs(transaction.createdDate).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {warranty.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            ))
          : null}
      </List>

      <Formik
        enableReinitialize
        initialValues={{
          description: "",
          employeeId: currentEmployeeId,
          storeId: currentStoreId,
          transactionId: transaction.id,
        }}
        onSubmit={async (values, actions) => {
          const { data } = await createWarranty({ ...values });

          if (data) {
            dispatch({
              type: SET_SNACKBAR_OPEN,
              variant: "success",
              message: "Warranty added successfully",
            });
          }

          const { data: result } = await getTransactionById(transaction.id);

          if (result) {
            setTransaction(result);
          }

          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <Grid
              container
              alignItems="flex-start"
              className={classes.commentArea}
              justify="flex-start"
            >
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Warranted By</InputLabel>
                  <Select
                    value={values.employeeId}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{
                      name: "createdBy",
                      id: "createdBy",
                    }}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {employee.firstName} {employee.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="filled-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  placeholder="type description here..."
                  variant="filled"
                  value={values.description}
                  onChange={handleChange}
                  name="description"
                  fullWidth
                />
              </Grid>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                className={classes.commentButton}
              >
                <Grid item>
                  <Grid container justify="space-between">
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Warranty;
