import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    zIndex: 0,
  },
  content: {
    marginTop: 80,
  },
  listItem: {
    width: 86,
    height: 80,
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    marginTop: theme.spacing(1),
    fontFamily: "Termina-Heavy, Termina",
    color: theme.palette.default.dark,
    lineHeight: "11px",
    fontSize: 12,
  },
  titleActive: {
    marginTop: theme.spacing(1),
    fontFamily: "Termina-Heavy, Termina",
    color: theme.palette.primary.main,
    lineHeight: "11px",
    fontSize: 12,
  },
  iconfont: {
    fontSize: "22px !important",
    color: theme.palette.system.darkGray,
  },
  iconfontActive: {
    fontSize: "22px !important",
    color: theme.palette.primary.main,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));
