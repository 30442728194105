import React from "react";

//@ts-expect-error because
import Barcode from "react-barcode";

interface SimpleBarcodeProps {
  format?: string;
  width?: number;
  height?: number;
  displayValue?: boolean;
  textAlign?: string;
  textPosition?: string;
  textMargin?: number;
  fontSize?: number;
  background?: string;
  lineColor?: string;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  value: string;
  fontOptions?: string;
  font?: string;
}

const SimpleBarcode: React.FC<SimpleBarcodeProps> = ({
  value = "123",
  format = "CODE128",
  width = 1.6,
  height = 14,
  displayValue = true,
  textAlign = "center",
  textPosition = "bottom",
  textMargin = 1,
  fontSize = 14,
  background = "#ffffff",
  lineColor = "#000000",
  marginBottom = 0,
  marginTop = 0,
  marginLeft = 12,
  fontOptions = "bold",
  font = "Arial",
}) => {
  return (
    <Barcode
      value={value}
      format={format}
      width={width}
      height={height}
      displayValue={displayValue}
      fontOptions={fontOptions}
      font={font}
      textAlign={textAlign}
      textPosition={textPosition}
      textMargin={textMargin}
      fontSize={fontSize}
      background={background}
      lineColor={lineColor}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    />
  );
};
export default SimpleBarcode;
