import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    fontSize: 24,
    fontFamily: "Termina-Heavy, Termina",
    fontWeight: 800,
    color: theme.palette.default.dark,
    lineHeight: "29px",
    letterSpacing: 1,
  },
  queryWrapper: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    width: 184,
  },
  tableRoot: {
    marginTop: 20,
  },
  inputRoot: {
    marginLeft: 20,
    height: 24,
    width: 188,
  },
  input: {
    paddingLeft: 20,
    borderWidth: 2,
    paddingTop: 3,
    paddingBottom: 3,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.27)",
    borderRadius: 3,
  },
  margin: {
    marginLeft: 0,
  },
  checkboxWrapper: {
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.system.darkGray,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
  },
  checkboxActive: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.system.darkGray,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  greyColorIcon: {
    fontSize: "22px !important",
    color: theme.palette.default.dark,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: "18px !important",
    },
  },
  mainColorIcon: {
    fontSize: "22px !important",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: "18px !important",
    },
  },
  redColorIcon: {
    fontSize: "22px !important",
    color: theme.palette.system.crimson,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: "18px !important",
    },
  },
  checkboxText: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  headerTitle: {
    fontSize: 16,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "11px",
    paddingBottom: 10,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  tableContent: {
    fontSize: 16,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "11px",
    paddingBottom: 10,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  buttonItem: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
    width: 28,
    height: 28,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.default.light,
    },
  },
  redColorFont: {
    color: "red",
  },
  tableCellRoot: {
    border: 0,
  },
  tableRowRoot: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}));

export const CssTextField = withStyles((theme: Theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "& .MuiFormLabel-filled": {
      color: theme.palette.system.darkGray,
      opacity: 1,
    },
    "& .MuiInputLabel-outlined": {
      fontFamily: "Helvetica",
      color: theme.palette.system.darkGray,
      fontSize: 14,
      transform: "translate(14px, 9px) scale(1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      opacity: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.27)",
        opacity: 1,
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        fontFamily: "Helvetica",
        color: "black",
        fontSize: 14,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input":
      {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
  },
}))(TextField);
