import React from "react";
import styled from "styled-components";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@material-ui/core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

const Icon = styled<React.FC<FontAwesomeIconProps>>(({ size, ...rest }) => (
  <FontAwesomeIcon size={size ?? "lg"} {...rest} />
))`
  padding-right: 5px;
`;

const ErrorBoundaryWrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background: #f1f6ff;
`;

const ErrorBoundaryContentWrapper = styled.div`
  margin-top: 165px;
  margin-left: 20%;
`;

const InfoWrapper = styled.div`
  margin-top: 20px;
`;

const BackButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  ErrorBoundaryState
> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(): ErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  handleRefresh = (): void => {
    window.location.reload();
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryWrapper>
          <ErrorBoundaryContentWrapper>
            <div>
              <Box
                fontWeight={700}
                fontSize={103}
                lineHeight="140px"
                color="#242A43"
              >
                :(
              </Box>
            </div>
            <div>
              <Box fontSize={50} lineHeight="68px" color="#242A43">
                Something went wrong.
              </Box>
            </div>

            <InfoWrapper>
              <div></div>
              <Box fontSize={20} lineHeight="27px" color="#242A43">
                This page is currently not available.
              </Box>
            </InfoWrapper>
            <BackButtonWrapper
              onClick={this.handleRefresh}
              data-testid="refresh"
            >
              <Icon icon={faRedoAlt} color="#3942D1" />
              <div>
                <Box
                  fontWeight={700}
                  fontSize={28}
                  lineHeight="38px"
                  color="#3942D1"
                >
                  Retry
                </Box>
              </div>
            </BackButtonWrapper>
          </ErrorBoundaryContentWrapper>
        </ErrorBoundaryWrapper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
