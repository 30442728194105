import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  form: {
    background: "white",
    width: 400,
    padding: "20px 30px"
  },
  error: {
    color: "red"
  },
  modalAction: {
    marginTop: "20px"
  }
}));
