import React from "react";
import { muiTheme } from "style/theme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiSnackbarContent from "@material-ui/core/SnackbarContent";
import MuiSnackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

type Variant = "success" | "info" | "warning" | "error";

interface SnackBarStyleProps {
  variant: Variant;
}

interface SnackBarProps extends SnackbarProps {
  variant: Variant;
  onClose: () => void;
}

const useStyles = makeStyles<typeof muiTheme, SnackBarStyleProps>(theme => ({
  root: ({ variant }) => {
    switch (variant) {
      case "success": {
        return {
          color: theme.palette.system.green,
          border: `2px solid ${theme.palette.system.green}`,
          backgroundColor: theme.palette.system.lightGreen
        };
      }
      case "info": {
        return {
          color: theme.palette.system.blue,
          border: `2px solid ${theme.palette.system.blue}`,
          backgroundColor: theme.palette.system.lightBlue
        };
      }
      case "warning": {
        return {
          color: theme.palette.system.yellow,
          border: `2px solid ${theme.palette.system.yellow}`,
          backgroundColor: theme.palette.system.lightYellow
        };
      }
      case "error": {
        return {
          color: theme.palette.system.red,
          border: `2px solid ${theme.palette.system.red}`,
          backgroundColor: theme.palette.system.lightRed
        };
      }
    }
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  action: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    fontSize: "1.6em",
    margin: "0 1em"
  },
  actionIcon: {
    fontSize: "1.6em",
    margin: "0 1em",
    cursor: "pointer"
  },
  messageWrapper: {
    display: "flex",
    alignItems: "center"
  }
}));

const Snackbar: React.FC<SnackBarProps> = props => {
  const { message, variant, onClose, ...rest } = props;
  const classes = useStyles({
    variant
  });
  return (
    <MuiSnackbar onClose={onClose} {...rest}>
      <MuiSnackbarContent
        message={
          <span
            className={classes.messageWrapper}
            id="snackbar-message"
            data-test="snackbar-message"
          >
            <span className={classes.icon}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>
            {message}
          </span>
        }
        action={
          <span
            onClick={onClose}
            className={classes.actionIcon}
            id="snackbar-action"
            data-test="snackbar-action"
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        }
        classes={{
          root: classes.root,
          action: classes.action,
          message: classes.message
        }}
      />
    </MuiSnackbar>
  );
};

Snackbar.defaultProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  autoHideDuration: 3000,
  variant: "success"
};

export default Snackbar;
