export const defaultColor = {
  black: "#2B2B2B",
  "old-silver": "#868686",
  "light-grey": "#484848",
  isabelline: "#EBEBEB",
  platinum: "#E7E7E7",
  "white-smoke": "#F6F6F6",
  white: "#FFFFFF",
};

export const primaryColor = {
  "brilliant-azure": "#2AB3E7",
  "bleu-de-france": "#2A92E8",
  "blue-jeans": "#54B1FF",
  bubbles: "#E6F4FF",
  glitter: "#DFEFFC",
};

export const secondaryColor = {
  "paradise-pink": "#E4395F",
  "dark-pink": "#E85D7C",
  "dingy-dungeon": "#D03457",
  "piggy-pink": "#F8E1E6",
  "misty-rose": "#FCE7EC",
};

export const systemColor = {
  green: "#66BB6A",
  "light-green": "#E8F5E9",
  blue: "#60A6E4",
  "light-blue": "#E7F2FB",
  yellow: "#FFB74C",
  "light-yellow": "#FFF4E4",
  red: "#F2545B",
  "light-red": "#FDE6E7",
  "transparent-gray": "#F3F6F9",
  "dark-gray": "#616161",
  crimson: "#CE0000",
};
