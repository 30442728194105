import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    background: "white",
    width: 369,
    paddingTop: 18,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    boxSizing: "border-box",
  },
  error: {
    color: "red",
  },
  modalAction: {
    marginTop: "20px",
  },
  editTitle: {
    fontSize: 16,
    fontFamily: "Termina-Heavy, Termina",
    fontWeight: 800,
    color: theme.palette.system.darkGray,
    lineHeight: "20px",
    letterSpacing: 1,
    display: "block",
    marginBottom: 24,
  },
  inputRoot: {
    width: "100%",
    marginBottom: 16,
  },
  input: {
    paddingLeft: 18,
    borderWidth: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.27)",
    borderRadius: 3,
  },
  margin: {
    marginLeft: 0,
  },
  select: {
    width: "100%",
    marginBottom: 16,
  },
  storeWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  storeText: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
    marginRight: 10,
  },
  storeItemWrapper: {
    paddingLeft: theme.spacing(1),
  },
  storeItemText: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "11px",
  },
  formControlLabelRoot: {
    marginLeft: 10,
    width: 104,
    marginBottom: 10,
  },
  storeItemInput: {
    width: 8,
    height: 8,
  },
  operateButtonWrapper: {
    width: "100%",
    marginTop: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    width: 82,
    height: 22,
    borderRadius: 3,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.default.dark,
    backgroundColor: "transparent",
    fontSize: 14,
    fontFamily: "Helvetica",
    fontWeight: "normal",
    color: theme.palette.default.dark,
    letterSpacing: 1,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
    },
  },
  saveButton: {
    marginLeft: 24,
    width: 84,
    height: 24,
    borderRadius: 3,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 14,
    fontFamily: "Helvetica",
    fontWeight: "normal",
    color: theme.palette.primary.main,
    letterSpacing: 1,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
    },
  },
  buttonMargin: {
    margin: theme.spacing(1),
  },
}));
export const CssTextField = withStyles((theme: Theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "& .MuiFormLabel-filled": {
      color: theme.palette.system.darkGray,
      opacity: 1,
    },
    "& .MuiInputLabel-outlined": {
      fontFamily: "Helvetica",
      color: theme.palette.system.darkGray,
      fontSize: 14,
      transform: "translate(14px, 12px) scale(1)",
      paddingLeft: 3,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      opacity: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.27)",
        opacity: 1,
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        fontFamily: "Helvetica",
        color: "black",
        fontSize: 14,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: theme.palette.primary.contrastText,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input":
      {
        paddingTop: 12.375,
        paddingBottom: 12.375,
      },
  },
}))(TextField);

export const SaveButton = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    border: "2px solid",
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    marginLeft: 24,
    width: 84,
    height: 26,
    borderRadius: 3,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 84,
      height: 24,
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.system.transparentGray,
    },
    "&:focus": {
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
}))(Button);

export const CancelButton = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    border: "2px solid",
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.default.dark,
    width: 84,
    height: 26,
    borderRadius: 3,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 84,
      height: 24,
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.system.transparentGray,
    },
    "&:focus": {
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      color: theme.palette.default.dark,
    },
  },
}))(Button);
