import React, { useState, useContext, useMemo, useCallback } from "react";
import { Modal, Input, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  useStyles,
  CssTextField,
  AddButton,
  SaveButton,
  CancelButton,
  DeleteButton,
} from "./employee.style";
import EmployeeModal from "./Modal";
import { globalContext } from "context/global";
import PermissionCheck from "common/PermissionCheck";
import { usePermission } from "hook";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, Form, FieldArray } from "formik";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import { updateEmployee, deleteEmployeeById, getEmployees } from "service/api";
import { EmployeeDTO, BaseTemplate, Role } from "type/index";

const Employee: React.FC = React.memo(() => {
  const { employees } = useContext(globalContext);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );
  const classes = useStyles();

  const selectedEmployee = useMemo(
    () => employees.find((employee) => employee.id === selectedEmployeeId),
    [employees, selectedEmployeeId]
  );
  const defaultRoles: BaseTemplate[] = [
    {
      key: "ADMIN",
      displayName: "Admin",
    },
    {
      key: "USER",
      displayName: "User",
    },
  ];
  const { setEmployees, stores, availableRoles } = useContext(globalContext);
  const selectRoles = availableRoles || defaultRoles;
  const dispatch = useSnackbarDispatch();
  const hasOwnerPermisson = usePermission(["OWNER"]);

  const selectedEmployeeDefault: EmployeeDTO = {
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    role: "ADMIN",
    gender: "MALE",
    birthday: "",
    email: "",
    tfn: "",
    contact: "",
    stores: [],
    defaultStore: null,
  };
  const {
    id,
    username,
    firstName,
    lastName,
    password,
    role,
    gender,
    birthday,
    email,
    tfn,
    contact,
    stores: employeeStores,
  } = selectedEmployee || selectedEmployeeDefault;

  const onDelete = useCallback(async () => {
    const { data } = await deleteEmployeeById(id);
    data && setEmployees((es) => es.filter((e) => e.id !== id));
    setDeleteVisible(false);
  }, [id, setEmployees]);
  return (
    <Grid container className={classes.wrapper}>
      <Grid container alignItems="center" justify="space-between">
        <span className={classes.title}>Staff</span>
        <PermissionCheck requiredRoles={["OWNER"]}>
          <AddButton
            onClick={() => setIsShowModal(true)}
            variant="contained"
            color="primary"
            disableRipple
            className={classes.buttonMargin}
          >
            Add New
          </AddButton>
        </PermissionCheck>
      </Grid>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>User Name</TableCell>
            <TableCell className={classes.headerTitle}>Name</TableCell>
            <TableCell className={classes.headerTitle}>Stores</TableCell>
            <TableCell className={classes.headerTitle}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee) => {
            return (
              <TableRow
                key={employee.id}
                classes={{
                  root: classes.tableRowRoot,
                }}
              >
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {employee.username}
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {employee.firstName} {employee.lastName}
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {employee.stores?.map((store) => `${store.name} `)}
                </TableCell>
                <TableCell
                  align="left"
                  classes={{
                    root: classes.tableCellRoot,
                  }}
                >
                  <div className={classes.buttonWrapper}>
                    <div
                      className={classes.buttonItem}
                      onClick={() => {
                        setSelectedEmployeeId(employee.id);
                        setEditVisible(true);
                      }}
                    >
                      <i
                        className={`iconfont iconEdit ${classes.greyColorIcon}`}
                        title="Edit"
                      />
                    </div>
                    <div
                      className={classes.buttonItem}
                      onClick={() => {
                        setSelectedEmployeeId(employee.id);
                        setDeleteVisible(true);
                      }}
                    >
                      <i
                        className={`iconfont iconDelete ${classes.redColorIcon}`}
                        title="Delete"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        <EmployeeModal
          isShowModal={isShowModal}
          closeModal={() => setIsShowModal(false)}
        />
      </Table>
      <Modal
        disableAutoFocus
        open={editVisible}
        onClose={() => {
          setEditVisible(false);
        }}
        className={classes.modal}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id,
            username,
            firstName,
            lastName,
            gender,
            role,
            birthday,
            email,
            password,
            tfn,
            contact,
            stores: employeeStores ?? [],
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            const { stores, ...rest } = values;
            try {
              await updateEmployee({
                ...rest,
                storeIds: stores.map((store) => store.id),
              });
              const { data } = await getEmployees();
              data && setEmployees(data.data);
            } catch (e) {
              dispatch({
                type: SET_SNACKBAR_OPEN,
                variant: "error",
                message: e.message ?? "Error: can't update employee",
              });
            }
            actions.setSubmitting(false);
            setEditVisible(false);
          }}
        >
          {({ isSubmitting, errors, touched, values, handleChange }) => (
            <Form className={classes.form}>
              <span className={classes.editTitle}>Staff Details</span>
              <Input
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                disableUnderline={true}
                placeholder="User Name"
                value={values.username}
                id="username"
                name="username"
                onChange={handleChange}
              />
              {errors.username && touched.username && (
                <div className={classes.error}>{errors.username}</div>
              )}
              {hasOwnerPermisson ? (
                <Autocomplete
                  id="combo-box-demo"
                  options={selectRoles}
                  getOptionLabel={(option) => option.displayName}
                  className={classes.select}
                  // eslint-disable-next-line
                  onChange={(event: any, newValue: BaseTemplate | null) => {
                    if (newValue != null) {
                      values.role = (newValue.key as Role) || null;
                    }
                  }}
                  defaultValue={selectRoles.find((roleItem) => {
                    return roleItem.key === values.role;
                  })}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      className={classes.margin}
                      label="Role"
                      variant="outlined"
                      id="custom-css-outlined-input"
                    />
                  )}
                />
              ) : (
                <Input
                  classes={{
                    root: classes.inputRoot,
                    input: classes.input,
                  }}
                  disableUnderline={true}
                  value={values.role}
                  onChange={handleChange}
                  disabled={true}
                  id="role"
                  name="role"
                />
              )}
              {errors.role && touched.role && (
                <div className={classes.error}>{errors.role}</div>
              )}
              <Input
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                disableUnderline={true}
                placeholder="First Name"
                value={values.firstName}
                onChange={handleChange}
                disabled={true}
                id="firstName"
                name="firstName"
              />
              {errors.firstName && touched.firstName && (
                <div className={classes.error}>{errors.firstName}</div>
              )}
              <Input
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                disableUnderline={true}
                placeholder="Last Name"
                value={values.lastName}
                onChange={handleChange}
                disabled={true}
                id="lastName"
                name="lastName"
              />
              {errors.lastName && touched.lastName && (
                <div className={classes.error}>{errors.lastName}</div>
              )}
              <Input
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                disableUnderline={true}
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                type="password"
                id="password"
                name="password"
              />
              {errors.password && touched.password && (
                <div className={classes.error}>{errors.password}</div>
              )}
              <div className={classes.storeWrapper}>
                <span className={classes.storeText}>Stores</span>
                <div className={classes.storeItemWrapper}>
                  <FieldArray
                    name="stores"
                    render={(arrayHelpers) => (
                      <>
                        {stores
                          .filter((store) => !store.isDeleted)
                          .map((store) => {
                            return (
                              <FormControlLabel
                                key={store.id}
                                classes={{
                                  label: classes.storeItemText,
                                  root: classes.formControlLabelRoot,
                                }}
                                control={
                                  <Checkbox
                                    key={store.id}
                                    value={store.name}
                                    color="primary"
                                    classes={{
                                      root: classes.storeItemInput,
                                    }}
                                    checked={values.stores
                                      .map((store) => store.id)
                                      .includes(store.id)}
                                    onChange={(e) => {
                                      e.target.checked
                                        ? arrayHelpers.push(store)
                                        : arrayHelpers.remove(
                                            values.stores.indexOf(store)
                                          );
                                    }}
                                  />
                                }
                                label={store.name}
                              />
                            );
                          })}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className={classes.operateButtonWrapper}>
                <CancelButton
                  variant="contained"
                  color="primary"
                  disableRipple
                  className={classes.buttonMargin}
                  onClick={() => {
                    setEditVisible(false);
                  }}
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  variant="contained"
                  color="primary"
                  disableRipple
                  className={classes.buttonMargin}
                  disabled={isSubmitting}
                  type="submit"
                >
                  Save
                </SaveButton>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        disableAutoFocus
        open={deleteVisible}
        onClose={() => {
          setDeleteVisible(false);
        }}
        className={classes.modal}
      >
        <div className={classes.form}>
          <span className={classes.deleteTitle}>
            Are you sure you want to delete this account?
          </span>
          <span className={classes.deleteDesc}>
            Please note that if you delete this account, it cannot be recovered
            in the future.
          </span>
          <div className={classes.operateButtonWrapper}>
            <CancelButton
              variant="contained"
              color="primary"
              disableRipple
              className={classes.buttonMargin}
              onClick={() => {
                setDeleteVisible(false);
              }}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              variant="contained"
              color="primary"
              disableRipple
              className={classes.buttonMargin}
              onClick={() => {
                onDelete();
              }}
            >
              Delete
            </DeleteButton>
          </div>
        </div>
      </Modal>
    </Grid>
  );
});

export default Employee;
