import React, { useReducer, useContext } from "react";
import Snackbar from "common/Snackbar";

type Variant = "success" | "info" | "warning" | "error";

interface Action {
  readonly type: "SET_SNACKBAR_OPEN" | "SET_SNACKBAR_CLOSE";
  readonly message?: string;
  readonly variant?: Variant;
}

interface State {
  readonly isOpen: boolean;
  readonly message: string;
  readonly variant: Variant;
}

type Dispatch = (action: Action) => void;

interface SnackBarProviderProps {
  children: React.ReactNode;
}

export const SET_SNACKBAR_CLOSE = "SET_SNACKBAR_CLOSE";
export const SET_SNACKBAR_OPEN = "SET_SNACKBAR_OPEN";

const SnackbarStateContext = React.createContext<State | undefined>(undefined);
const SnackbarDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);
const initialState: State = {
  isOpen: false,
  message: "",
  variant: "success"
};

const snackbarReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_SNACKBAR_OPEN: {
      return {
        isOpen: true,
        message: action.message || "",
        variant: action.variant || "success"
      };
    }
    case SET_SNACKBAR_CLOSE: {
      return {
        ...state,
        isOpen: false
      };
    }
    default:
      return state;
  }
};

const SnackbarWrapper: React.FC = () => {
  const { isOpen, message, variant } = useSnackbarState();
  const snackbarDispatch = useSnackbarDispatch();
  return (
    <Snackbar
      className="printHiddenArea"
      autoHideDuration={3000}
      open={isOpen}
      message={message}
      variant={variant}
      onClose={() => snackbarDispatch({ type: SET_SNACKBAR_CLOSE })}
    />
  );
};

const SnackbarProvider: React.FC<SnackBarProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<typeof snackbarReducer>(
    snackbarReducer,
    initialState
  );

  return (
    <SnackbarStateContext.Provider value={state}>
      <SnackbarDispatchContext.Provider value={dispatch}>
        {children}
        <SnackbarWrapper />
      </SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  );
};

const useSnackbarState = (): State => {
  const context = useContext(SnackbarStateContext);
  if (context === undefined) {
    throw new Error("useSnackbarState must be used within a SnackbarProvider");
  }
  return context;
};

const useSnackbarDispatch = (): Dispatch => {
  const context = useContext(SnackbarDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSnackbarDispatch must be used within a SnackbarProvider"
    );
  }
  return context;
};

export { SnackbarProvider, useSnackbarState, useSnackbarDispatch };
