import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: "35px",
  },
  title: {
    fontSize: 24,
    fontFamily: "Termina-Heavy, Termina",
    fontWeight: 800,
    color: theme.palette.default.dark,
    lineHeight: "29px",
    letterSpacing: 1,
  },
  addnew: {
    width: 100,
    height: 32,
    borderRadius: 18,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 14,
    fontFamily: "Helvetica",
    fontWeight: "normal",
    color: theme.palette.primary.main,
    letterSpacing: 1,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
    },
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 84,
      height: 24,
      fontSize: 14,
    },
  },
  tableRoot: {
    marginTop: 20,
  },
  drawerWrapper: {
    width: 540,
  },
  cellWrapper: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eeeeee",
    },
  },
  select: {
    marginLeft: theme.spacing(2),
  },
  headerTitle: {
    fontSize: 16,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "11px",
    paddingBottom: 10,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  tableContent: {
    fontSize: 16,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "11px",
    paddingBottom: 10,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  buttonMargin: {
    margin: theme.spacing(1),
  },
}));
export const AddButton = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    width: 100,
    height: 32,
    borderRadius: 18,
    borderWidth: 2,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 90,
      height: 30,
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.system.transparentGray,
    },
    "&:focus": {
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
}))(Button);
