import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: "25px",
    },
    title: {
      fontSize: 24,
      fontFamily: "Termina-Heavy, Termina",
      fontWeight: 800,
      color: theme.palette.default.dark,
      lineHeight: "29px",
      letterSpacing: 1,
    },
    queryContainer: {
      paddingTop: 12,
    },
    queryWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    select: {
      width: 184,
    },
    checkboxWrapper: {
      marginLeft: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    checkbox: {
      width: 10,
      height: 10,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.system.darkGray,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.contrastText,
    },
    checkboxActive: {
      width: 10,
      height: 10,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.system.darkGray,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
    },
    checkboxText: {
      marginLeft: 10,
      fontSize: 14,
      fontFamily: "Helvetica",
      color: theme.palette.system.darkGray,
      lineHeight: "11px",
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: 14,
      },
    },
    inputRoot: {
      marginLeft: 20,
      height: 24,
      width: 188,
    },
    input: {
      paddingLeft: 20,
      borderWidth: 2,
      paddingTop: 3,
      paddingBottom: 3,
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.27)",
      borderRadius: 3,
    },
    addnew: {
      width: 100,
      height: 32,
      borderRadius: 18,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      backgroundColor: "transparent",
      fontSize: 14,
      fontFamily: "Helvetica",
      fontWeight: "normal",
      color: theme.palette.primary.main,
      letterSpacing: 1,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.system.transparentGray,
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: 84,
        height: 24,
        fontSize: 14,
      },
    },
    tableRoot: {
      marginTop: 20,
    },
    headerTitle: {
      fontSize: 16,
      fontFamily: "Helvetica",
      color: theme.palette.default.dark,
      lineHeight: "11px",
      paddingBottom: 10,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: 14,
      },
    },
    tableContent: {
      fontSize: 16,
      fontFamily: "Helvetica",
      color: theme.palette.default.dark,
      lineHeight: "11px",
      paddingBottom: 10,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: 14,
      },
    },
    mainColorIcon: {
      fontSize: "22px !important",
      color: theme.palette.primary.main,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: "18px !important",
      },
    },
    greyColorIcon: {
      fontSize: "22px !important",
      color: theme.palette.default.dark,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: "18px !important",
      },
    },
    redColorIcon: {
      fontSize: "22px !important",
      color: theme.palette.system.crimson,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: "18px !important",
      },
    },
    marginRight0: {
      marginRight: 0,
    },
    buttonWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    movedWrapper: {
      marginLeft: 24,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    movedText: {
      fontSize: 14,
      fontFamily: "Helvetica",
      color: theme.palette.primary.main,
      lineHeight: "11px",
      marginRight: theme.spacing(1),
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: 14,
      },
    },
    buttonItem: {
      marginRight: theme.spacing(1),
      cursor: "pointer",
      width: 28,
      height: 28,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        backgroundColor: theme.palette.default.light,
      },
    },
    line: {
      height: 14,
      width: 2,
      backgroundColor: "rgba(42, 179, 231, 0.5)",
      display: "inline-block",
      marginLeft: 10,
      marginRight: 10,
    },
    barcodeWrapper: {
      width: 170,
      height: 84,
      display: "none",
    },
    barcodeTextWrapper: {
      marginTop: -6,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 32,
    },
    barcodeTextLeft: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: 10,
    },
    barcodeTextRight: {
      width: 100,
      display: "flex",
      flexDirection: "column",
      paddingRight: 10,
    },
    barcodeTextStore: {
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Arial",
      fontWeight: "bold",
    },
    barcodeDisplayNone: {
      display: "none",
    },
    barcodeDisplayBlock: {
      display: "block",
    },
    barcodeText: {
      marginTop: 1,
      textAlign: "left",
      fontSize: 14,
      fontFamily: "Arial",
      fontWeight: "bold",
    },
    issueWrapper: {
      paddingLeft: 32,
      paddingRight: 20,
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      alignItems: "center",
    },
    margin: {
      marginLeft: 0,
    },
    switchBase: {
      color: theme.palette.primary.main,
    },
    track: {
      backgroundColor: "#D2D2D2",
    },
    buttonMargin: {
      margin: theme.spacing(1),
    },
    redColorFont: {
      color: "red",
    },
    tableCellRoot: {
      border: 0,
    },
    tableRowRoot: {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  })
);

export const CssTextField = withStyles((theme: Theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "& .MuiFormLabel-filled": {
      color: theme.palette.system.darkGray,
      opacity: 1,
    },
    "& .MuiInputLabel-outlined": {
      fontFamily: "Helvetica",
      color: theme.palette.system.darkGray,
      fontSize: 14,
      transform: "translate(14px, 9px) scale(1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      opacity: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.27)",
        opacity: 1,
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        fontFamily: "Helvetica",
        color: "black",
        fontSize: 14,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input":
      {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
  },
}))(TextField);

export const AddButton = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    width: 100,
    height: 32,
    borderRadius: 18,
    borderWidth: 2,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 90,
      height: 30,
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.system.transparentGray,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.system.transparentGray,
    },
    "&:focus": {
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
}))(Button);
