import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import {
  primaryColor,
  secondaryColor,
  defaultColor,
  systemColor,
} from "./color";

declare module "@material-ui/core/styles/createPalette" {
  export interface SimplePaletteColorOptions {
    xlight: string;
    xxlight: string;
  }

  export interface DefaultColorOptions extends SimplePaletteColorOptions {
    disabled: string;
  }

  export interface Palette {
    system: {
      white: string;
      green: string;
      lightGreen: string;
      blue: string;
      lightBlue: string;
      yellow: string;
      lightYellow: string;
      red: string;
      lightRed: string;
      transparentGray: string;
      darkGray: string;
      crimson: string;
    };
    default: SimplePaletteColorOptions;
  }

  export interface PaletteOptions {
    system: {
      white: string;
      green: string;
      lightGreen: string;
      blue: string;
      lightBlue: string;
      yellow: string;
      lightYellow: string;
      red: string;
      lightRed: string;
    };
    default: DefaultColorOptions;
  }
}

const theme = {
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, Arial, sans-serif",
  },
  palette: {
    primary: {
      /**
       * White: #FFFFFF
       */
      contrastText: "#FFFFFF",
      /**
       * Brilliant Azure: #2EA0FF
       */
      main: primaryColor["brilliant-azure"],
      /**
       * Blue De France: #2A92E8
       */
      dark: primaryColor["bleu-de-france"],
      /**
       * Blue Jeans: #54B1FF
       */
      light: primaryColor["blue-jeans"],
      /**
       * Glitter: #E6F4FF
       */
      xlight: primaryColor.glitter,
      /**
       * Bubble: #DFEFFC
       */
      xxlight: primaryColor.bubbles,
    },
    secondary: {
      /**
       * White: #FFFFFF
       */
      contrastText: "#FFFFFF",
      /**
       * Paradise Pink: #E4395F
       */
      main: secondaryColor["paradise-pink"],
      /**
       * Dingungeony D: #E85D7C
       */
      dark: secondaryColor["dingy-dungeon"],
      /**
       * Dark Pink: #D03457
       */
      light: secondaryColor["dark-pink"],
      /**
       * Piggy Pink: #F8E1E6
       */
      xlight: secondaryColor["piggy-pink"],
      /**
       * Misty Rose: #FCE7EC
       */
      xxlight: secondaryColor["misty-rose"],
    },
    default: {
      /**
       * Black: #2B2B2B
       */
      contrastText: defaultColor.black,
      /**
       * Old Silver: #868686
       */
      disabled: defaultColor["old-silver"],
      /**
       * Platinum: #D2D2D2
       */
      main: defaultColor["platinum"],
      /**
       * Light Grey: #EBEBEB
       */
      dark: defaultColor["light-grey"],
      /**
       * Isabelline: #E7E7E7
       */
      light: defaultColor.isabelline,
      /**
       * White Smoke: #F6F6F6
       */
      xlight: defaultColor["white-smoke"],
      /**
       * White: #FFFFFF
       */
      xxlight: defaultColor.white,
    },
    system: {
      /**
       * White: #FFFFFF
       */
      white: defaultColor.white,
      /**
       * Green: #66BB6A
       */
      green: systemColor.green,
      /**
       * Light Green: #E8F5E9
       */
      lightGreen: systemColor["light-green"],
      /**
       * Blue: #60A6E4
       */
      blue: systemColor.blue,
      /**
       * Light Blue: #E7F2FB
       */
      lightBlue: systemColor["light-blue"],
      /**
       * Yellow: #FFB74C
       */
      yellow: systemColor.yellow,
      /**
       * Light Yellow: #FFF4E4
       */
      lightYellow: systemColor["light-yellow"],
      /**
       * Red: #F2545B
       */
      red: systemColor.red,
      /**
       * Light Red: #FDE6E7
       */
      lightRed: systemColor["light-red"],

      transparentGray: systemColor["transparent-gray"],

      darkGray: systemColor["dark-gray"],

      crimson: systemColor["crimson"],
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export const muiTheme = responsiveFontSizes(createMuiTheme(theme));
