import React, { useState, useEffect } from "react";
import {
  getStores,
  getEmployees,
  getAvailableTransactionStatus,
  getAvailableInspection,
  getAvailableGenders,
  getAvailableRole,
} from "service/api";
import type { StoreDTO, EmployeeDTO, BaseTemplate } from "type";

interface GlobalContext {
  stores: StoreDTO[];
  employees: EmployeeDTO[];
  currentStoreId: string | undefined;
  genders: BaseTemplate[];
  transactionStatus: BaseTemplate[];
  inspections: BaseTemplate[];
  availableRoles: BaseTemplate[];
  roles: string[] | undefined;
  currentEmployeeId: string | undefined;
  setRoles: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  setCurrentEmployeeId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setStores: React.Dispatch<React.SetStateAction<StoreDTO[]>>;
  setEmployees: React.Dispatch<React.SetStateAction<EmployeeDTO[]>>;
  setCurrentStoreId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const globalContext = React.createContext<GlobalContext>({
  stores: [],
  employees: [],
  currentStoreId: void 0,
  genders: [],
  availableRoles: [],
  transactionStatus: [],
  inspections: [],
  roles: void 0,
  currentEmployeeId: void 0,
  setRoles: () => {},
  setCurrentEmployeeId: () => {},
  setStores: () => {},
  setEmployees: () => {},
  setCurrentStoreId: () => {},
});

export const GlobalProvider: React.FC = ({ children }) => {
  const [roles, setRoles] = useState<string[] | undefined>(void 0);
  const [currentEmployeeId, setCurrentEmployeeId] = useState<
    string | undefined
  >(void 0);
  const [currentStoreId, setCurrentStoreId] = useState<string | undefined>(
    void 0
  );
  const [stores, setStores] = useState<StoreDTO[]>([]);
  const [employees, setEmployees] = useState<EmployeeDTO[]>([]);
  const [genders, setGenders] = useState<BaseTemplate[]>([]);
  const [availableRoles, setAvailableRoles] = useState<BaseTemplate[]>([]);
  const [inspections, setInspections] = useState<BaseTemplate[]>([]);
  const [transactionStatus, setTransactionStatus] = useState<BaseTemplate[]>(
    []
  );

  useEffect(() => {
    (async () => {
      if (roles?.includes("ADMIN") || roles?.includes("OWNER")) {
        const { data: stores } = await getStores({ page: 0, perPage: 1000 });
        stores && setStores(stores.data);
      }
    })();
  }, [roles]);

  useEffect(() => {
    (async () => {
      const params: Record<string, unknown> = {
        page: 0,
        perPage: 1000,
        storeId: currentStoreId,
      };

      const { data: employees } = await getEmployees(params);
      employees && setEmployees(employees.data);
    })();
  }, [currentStoreId]);

  useEffect(() => {
    (async () => {
      if (roles && roles.length > 0) {
        const { data: status } = await getAvailableTransactionStatus();
        status && setTransactionStatus(status);
      }
    })();
  }, [roles]);

  useEffect(() => {
    (async () => {
      if (roles && roles.length > 0) {
        const { data: inspections } = await getAvailableInspection();
        inspections && setInspections(inspections);
      }
    })();
  }, [roles]);

  useEffect(() => {
    (async () => {
      if (roles && roles.length > 0) {
        const { data: genders } = await getAvailableGenders();
        genders && setGenders(genders);
      }
    })();
  }, [roles]);

  useEffect(() => {
    (async () => {
      if (roles && roles.length > 0) {
        const { data: roles } = await getAvailableRole();
        roles && setAvailableRoles(roles);
      }
    })();
  }, [roles]);

  return (
    <globalContext.Provider
      value={{
        stores,
        employees,
        currentStoreId,
        transactionStatus,
        inspections,
        roles,
        availableRoles,
        genders,
        currentEmployeeId,
        setRoles,
        setCurrentEmployeeId,
        setStores,
        setEmployees,
        setCurrentStoreId,
      }}
    >
      {children}
    </globalContext.Provider>
  );
};

export default GlobalProvider;
