import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { createComment, deleteCommentById, updateComment } from "service/api";
import { EmployeeDTO, TransactionDTO, CommentDTO } from "type";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import dayjs from "dayjs";
import { SET_SNACKBAR_OPEN, useSnackbarDispatch } from "context/snackbar";
import PermissionCheck from "common/PermissionCheck";
import { globalContext } from "context/global";

interface CommentProps {
  transaction: TransactionDTO;
  employees: EmployeeDTO[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
  },
  commentArea: {
    padding: `0 0 0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      padding: 0,
    },
  },
  commentButton: {
    marginTop: theme.spacing(1),
  },
  title: {
    padding: `${theme.spacing(3)}px 0 0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      padding: 0,
      marginTop: 0,
    },
  },
}));

const Comment: React.FC<CommentProps> = ({ transaction, employees }) => {
  const classes = useStyles();
  const { currentEmployeeId } = useContext(globalContext);
  const [initialComments, setInitialComments] = useState<CommentDTO[]>(
    () => transaction.comments
  );
  const [selectedComment, setSelectedComment] = useState<CommentDTO | null>(
    null
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const dispatch = useSnackbarDispatch();

  return (
    <Box>
      <Typography variant="h5" className={classes.title} color="primary">
        <strong>Comments</strong>
      </Typography>
      <List className={classes.root}>
        {initialComments.map((comment) => (
          <Grid key={comment.id} container justify="space-between">
            <Grid item xs={10}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={`${comment.employee.firstName} ${comment.employee.lastName}`}
                    src="/static/images/avatar/1.jpg"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      {`${comment.employee.firstName} ${comment.employee.lastName}`}
                      <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                      >
                        {dayjs(comment.lastModifiedDate).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {comment.content}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
            <PermissionCheck requiredRoles={["ADMIN"]}>
              <Grid item xs={1}>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={() => {
                    setSelectedComment(comment);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="delete"
                  onClick={async () => {
                    const { data } = await deleteCommentById(comment.id);
                    if (data) {
                      setInitialComments((initialComments) => {
                        return initialComments.filter(
                          (initialComment) => initialComment.id !== comment.id
                        );
                      });
                      setSelectedComment(null);
                      dispatch({
                        type: SET_SNACKBAR_OPEN,
                        variant: "success",
                        message: "Delete Successfully!",
                      });
                    }
                    setSubmitted(!submitted);
                  }}
                  color="secondary"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            </PermissionCheck>
          </Grid>
        ))}
      </List>
      {selectedComment ? (
        <Formik
          enableReinitialize
          initialValues={{
            id: selectedComment?.id,
            createdById: selectedComment?.employee.id,
            content: selectedComment?.content,
          }}
          onSubmit={async (values, actions) => {
            const { data } = await updateComment({
              content: values.content ?? "",
              employeeId: values.createdById ?? "",
              id: values?.id ?? "",
              transactionId: transaction.id,
            });
            if (data) {
              setInitialComments((initialComments) =>
                initialComments.map((initialComment) => {
                  if (initialComment.id === data.id) {
                    return data;
                  }
                  return initialComment;
                })
              );
              dispatch({
                type: SET_SNACKBAR_OPEN,
                variant: "success",
                message: "Update Successfully!",
              });
              setSelectedComment(null);
            }
            actions.resetForm();
          }}
        >
          {({ values, isSubmitting, handleChange }) => (
            <Form>
              <Grid
                container
                alignItems="flex-start"
                className={classes.commentArea}
                justify="flex-start"
              >
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel>Commented By</InputLabel>
                    <Select
                      value={values.createdById}
                      onChange={handleChange}
                      fullWidth
                      inputProps={{
                        name: "createdById",
                        id: "createdById",
                      }}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee.id} value={employee.id}>
                          {employee.firstName} {employee.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="filled-multiline-static"
                    label="Comment"
                    multiline
                    rows={4}
                    placeholder="type your comment here..."
                    variant="filled"
                    value={values.content}
                    onChange={handleChange}
                    name="content"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  className={classes.commentButton}
                >
                  <Grid item xs={7}>
                    <Grid container justify="space-between">
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Update
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="secondary"
                        onClick={() => setSelectedComment(null)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            createdById: currentEmployeeId ?? "",
            content: "",
          }}
          onSubmit={async (values, actions) => {
            const results = await createComment({
              content: values.content,
              employeeId: values.createdById,
              transactionId: transaction.id,
            });

            if (results.error) {
              dispatch({
                type: SET_SNACKBAR_OPEN,
                variant: "error",
                message: results.error,
              });
            }

            if (results.data) {
              setInitialComments((initialComments) => {
                const newComments = [...initialComments];
                newComments.push(results.data as CommentDTO);
                return newComments;
              });
              dispatch({
                type: SET_SNACKBAR_OPEN,
                variant: "success",
                message: "Create Successfully!",
              });
            }
            actions.resetForm();
            setSubmitted(!submitted);
          }}
        >
          {({ values, isSubmitting, handleChange }) => (
            <Form>
              <Grid
                container
                alignItems="flex-start"
                className={classes.commentArea}
                justify="flex-start"
              >
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Commented By</InputLabel>
                    <Select
                      value={values.createdById}
                      onChange={handleChange}
                      fullWidth
                      inputProps={{
                        name: "createdById",
                        id: "createdById",
                      }}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee.id} value={employee.id}>
                          {employee.firstName} {employee.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="filled-multiline-static"
                    label="Comment"
                    multiline
                    fullWidth
                    rows={4}
                    placeholder="type your comment here..."
                    variant="filled"
                    value={values.content}
                    onChange={handleChange}
                    name="content"
                  />
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  className={classes.commentButton}
                >
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default Comment;
