import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface ActionDialogProps {
  open: boolean;
  onClose: () => void;
  info: string;
  handleConfirm: (repairedBy: string) => void;
}

const ActionDialog: React.FC<ActionDialogProps> = ({
  open,
  onClose,
  info,
  handleConfirm,
}) => {
  const classes = useStyles();
  const [repairedBy, setRepairedBy] = useState<string>("");
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          <div>{info}</div>
        </DialogContentText>
      </DialogContent>
      <div className={classes.inputWrapper}>
        <TextField
          id="outlined-basic"
          label="Repaired by"
          variant="outlined"
          onChange={(event) => setRepairedBy(event.target.value)}
        />
      </div>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          No
        </Button>
        <Button color="primary" onClick={() => handleConfirm(repairedBy)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
