import { Grid, Input } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { globalContext } from "context/global";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SET_SNACKBAR_OPEN, useSnackbarDispatch } from "context/snackbar";
import dayjs from "dayjs";
import { useDebounce } from "hook";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import {
  deleteTransactionById,
  getTransactions,
  sendEmail,
  updateTransactionStatusById,
} from "service/api";
import type { TransactionDTO } from "type";
import ActionDialog from "./ActionDialog";
import MoveDialog from "./MoveDialog";
import MoveHistory from "./MoveHistory";
import { useStyles, CssTextField, AddButton } from "./transaction.style";
import config from "config";
import utc from "dayjs/plugin/utc";
import SimpleBarcode from "./SimpleBarcode";

dayjs.extend(utc);

type SearchType = "reference" | "name" | "contact";

const Transaction = React.memo(() => {
  const history = useHistory();
  const classes = useStyles();
  const { currentStoreId, currentEmployeeId, employees } =
    useContext(globalContext);
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [includeAll, setIncludeAll] = useState<boolean>(false);
  const [type, setType] = useState<SearchType>("contact");
  const [filter, setFilter] = useState<string>("");
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [moveHistoryOpen, setMoveHistoryOpen] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [transactions, setTransactions] = useState<TransactionDTO[]>([]);
  const [transaction, setTransaction] = useState<TransactionDTO | null>(null);
  const [transactionId, setTransactionId] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const dispatch = useSnackbarDispatch();
  const debouncedFilter = useDebounce(filter);
  const [showMoved, setShowMoved] = useState<boolean>(false);
  const [barcodeStore, setBarcodeStore] = useState<string>("");
  const [barcodeCustomerName, setBarcodeCustomerName] = useState<string>("");
  const [barcodeReference, setBarcodeReference] = useState<string>("12344");
  const [passcode, setPasscode] = useState<string>("");
  const [issue, setIssue] = useState<string>("");

  useEffect(() => {
    console.log("employees", employees);
    if (currentStoreId !== void 0) {
      (async () => {
        const params: Record<string, unknown> = {
          page,
          perPage: 10,
          status: "RECEIVED",
        };

        if (currentStoreId && !includeAll) {
          params.storeId = currentStoreId;
        }
        if (type === "reference" && debouncedFilter !== "") {
          params.reference = debouncedFilter;
        }

        if (type === "name" && debouncedFilter !== "") {
          params.customerName = debouncedFilter;
        }
        if (type === "contact" && debouncedFilter !== "") {
          params.contact = debouncedFilter;
        }
        if (showMoved) {
          params.showCreatedAt = true;
        }
        const { data } = await getTransactions(params);
        if (data) {
          setTransactions(data.data);
          setTotal(data.total);
        }
      })();
    }
  }, [
    currentStoreId,
    debouncedFilter,
    page,
    showMoved,
    type,
    includeAll,
    employees,
  ]);

  const handleClose = useCallback(async () => {
    setOpen(false);
    const { data } = await getTransactions({
      page,
      perPage: 10,
      storeId: currentStoreId,
    });

    if (data) {
      setTransactions(data.data);
      setTotal(data.total);
    }
  }, [currentStoreId, page]);

  const handleMoveClose = useCallback(async () => {
    setDialogOpen(false);
    const { data } = await getTransactions({
      page,
      perPage: 10,
      storeId: currentStoreId,
    });

    if (data) {
      setTransactions(data.data);
      setTotal(data.total);
    }
  }, [currentStoreId, page]);

  const handleChangeState = useCallback(
    async (repairedBy: string, id: string, index: number) => {
      const { data } = await updateTransactionStatusById(
        id,
        "TO_BE_COLLECTED",
        currentEmployeeId,
        repairedBy
      );
      if (data) {
        setTransactions((ts) =>
          ts.filter((t, i) => {
            return index !== i;
          })
        );
        dispatch({
          type: SET_SNACKBAR_OPEN,
          variant: "success",
          message: "Status changed successfully",
        });
      }
      setOpen(false);
    },
    [currentEmployeeId, dispatch]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteTransactionById(id);
      setTransactions((ts) => ts.filter((t) => t.id !== id));
      dispatch({
        type: SET_SNACKBAR_OPEN,
        variant: "success",
        message: "Delete Successfully!",
      });
      setDeleteOpen(false);
    },
    [dispatch]
  );

  const printBarcode = (
    reference: string,
    customerName: string,
    createdTime: string,
    passcode: string,
    issue: string
  ) => {
    let storeName = "No Store Selected";
    for (let i = 0; i < employees.length; i++) {
      if (employees[i].id === currentEmployeeId) {
        const currentStores = employees[i]?.stores ?? [];
        for (let j = 0; j < currentStores.length; j++) {
          if (currentStoreId === currentStores[j].id) {
            storeName = currentStores[j].name;
          }
        }
      }
    }
    setBarcodeReference(reference);
    setBarcodeStore(storeName);
    setBarcodeCustomerName(customerName);
    setPasscode(passcode);
    setIssue(issue);
    setTimeout(getBarcode, 1000);
  };
  const getBarcode = () => {
    const barcode = window.document.getElementById("barcode");
    if (barcode) {
      window.document.body.innerHTML = barcode.innerHTML;
      window.print();
      window.location.reload();
    }
  };
  const queryFieldList: string[] = [
    "Contact Number",
    "Reference",
    "Customer Name",
  ];
  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid container alignItems="center" justify="space-between">
          <span className={classes.title}>Dashboard</span>
          <Grid
            container
            direction="row"
            alignContent="center"
            justify="space-between"
            xs={12}
            classes={{ root: classes.queryContainer }}
          >
            <div className={classes.queryWrapper}>
              <Autocomplete
                id="combo-box-demo"
                options={queryFieldList}
                getOptionLabel={(option) => option}
                className={classes.select}
                // eslint-disable-next-line
                onChange={(event: any, newValue: string | null) => {
                  if (newValue === "Contact Number") {
                    setType("contact" as SearchType);
                  } else if (newValue === "Reference") {
                    setType("reference" as SearchType);
                  } else if (newValue === "Customer Name") {
                    setType("name" as SearchType);
                  } else {
                  }
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    className={classes.margin}
                    label="Search by"
                    variant="outlined"
                    id="custom-css-outlined-input"
                  />
                )}
              />
              <Input
                id="filter"
                value={filter}
                onChange={(event) => setFilter(event.target.value as string)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                disableUnderline={true}
                placeholder="Search"
              />
              <div className={classes.checkboxWrapper}>
                <div
                  className={
                    includeAll ? classes.checkboxActive : classes.checkbox
                  }
                  onClick={() => {
                    setIncludeAll(!includeAll);
                  }}
                />
                <span className={classes.checkboxText}>All Stores</span>
              </div>
              <div className={classes.movedWrapper}>
                <span className={classes.movedText}>Moved Transcation</span>
                <Switch
                  checked={showMoved}
                  onChange={() => setShowMoved((c) => !c)}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  classes={{
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                />
              </div>
            </div>
            <AddButton
              onClick={() => history.push("/transaction/new")}
              variant="contained"
              color="primary"
              disableRipple
              className={classes.buttonMargin}
            >
              Add New
            </AddButton>
          </Grid>
        </Grid>
        <Table size="small" classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerTitle}>Customer</TableCell>
              <TableCell className={classes.headerTitle}>Contact</TableCell>
              <TableCell className={classes.headerTitle}>Due Date</TableCell>
              <TableCell className={classes.headerTitle}>
                Device Model
              </TableCell>
              <TableCell className={classes.headerTitle}>
                Created Time
              </TableCell>
              <TableCell className={classes.headerTitle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(showMoved
              ? transactions.filter(
                  (t) =>
                    t.transactionTransitions.sort((a, b) => {
                      return (
                        +new Date(a.createdDate) - +new Date(b.createdDate)
                      );
                    })[0].store.id === currentStoreId &&
                    t.transactionTransitions.length > 1
                )
              : transactions
            ).map((transaction, index) => {
              return (
                <TableRow
                  hover
                  key={transaction.id}
                  classes={{
                    root: classes.tableRowRoot,
                  }}
                  onClick={(event) =>
                    history.push({
                      pathname: `/transaction/${transaction.id}`,
                      state: { transaction, from: "dashboard" },
                    })
                  }
                >
                  <TableCell
                    className={classes.tableContent}
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  >
                    {transaction.customerName}
                  </TableCell>
                  <TableCell
                    className={classes.tableContent}
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  >
                    {transaction.contact}
                  </TableCell>
                  {new Date(
                    (transaction?.pickupTime || "").replace(/-/g, "/")
                  ) > new Date() ? (
                    <TableCell
                      className={classes.tableContent}
                      classes={{
                        root: classes.tableCellRoot,
                      }}
                    >
                      {transaction.pickupTime}
                    </TableCell>
                  ) : (
                    <TableCell
                      className={`${classes.tableContent} ${classes.redColorFont}`}
                      classes={{
                        root: classes.tableCellRoot,
                      }}
                    >
                      {transaction.pickupTime}
                    </TableCell>
                  )}

                  <TableCell
                    className={classes.tableContent}
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  >
                    {transaction.device}
                  </TableCell>
                  <TableCell
                    className={classes.tableContent}
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  >
                    <Typography variant="body2">
                      {dayjs
                        .utc(transaction.createdDate.substring(0, 23))
                        .local()
                        .format("YYYY/MM/DD h:mm A")}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="left"
                    classes={{ root: classes.tableCellRoot }}
                  >
                    <div className={classes.buttonWrapper}>
                      <div
                        title="Next"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setIndex(index);
                          setTransactionId(transaction.id);
                          setOpen(true);
                        }}
                      >
                        <i
                          className={`iconfont iconTick ${classes.mainColorIcon}`}
                        ></i>
                      </div>
                      <div
                        title="Email"
                        className={classes.buttonItem}
                        onClick={async (event) => {
                          event.stopPropagation();
                          const { data, error } = await sendEmail(
                            transaction.id,
                            "authorisation"
                          );
                          dispatch({
                            type: SET_SNACKBAR_OPEN,
                            message: data?.message ?? error,
                            variant: data ? "success" : "error",
                          });
                        }}
                      >
                        <i
                          className={`iconfont iconEmail1 ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      <div
                        title="Download"
                        className={`${classes.buttonItem} ${classes.marginRight0}`}
                        onClick={async (event) => {
                          event.stopPropagation();
                          window.open(
                            `${config.baseUrl}/pdf/${transaction.id}/authorisation`,
                            "_blank"
                          );
                        }}
                      >
                        <i
                          className={`iconfont iconDownload ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      <span className={classes.line} />
                      <div
                        title="Send"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setTransactionId(transaction.id);
                          setDialogOpen(true);
                        }}
                      >
                        <i
                          className={`iconfont iconSend ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      <div
                        title="History"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setMoveHistoryOpen(true);
                          setTransaction(transaction);
                        }}
                      >
                        <i
                          className={`iconfont iconRecord ${
                            transaction.transactionTransitions.length > 1
                              ? classes.greyColorIcon
                              : classes.mainColorIcon
                          }`}
                        ></i>
                      </div>
                      <div
                        title="Barcode"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          printBarcode(
                            transaction.reference,
                            transaction.customerName,
                            dayjs
                              .utc(transaction.createdDate.substring(0, 23))
                              .local()
                              .format("DD/MM/YYYY"),
                            transaction.passcode,
                            transaction.issue
                          );
                        }}
                      >
                        <i
                          className={`iconfont iconbardcode ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      {/* <IconButton
                      title="Barcode"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        printBarcode(
                          transaction.reference,
                          transaction.customerName,
                          dayjs
                            .utc(transaction.createdDate.substring(0, 23))
                            .local()
                            .format("DD/MM/YYYY"),
                          transaction.passcode,
                          transaction.issue
                        );
                      }}
                    >
                      <ComputerICon color="primary" />
                    </IconButton> */}
                      <div
                        title="PDF"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          window.location.href = transaction.pdfUrl;
                        }}
                      >
                        <i
                          className={`iconfont iconpdf ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      <span className={classes.line} />
                      <div
                        title="Delete"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setDeleteOpen(true);
                          setTransactionId(transaction.id);
                        }}
                      >
                        <i
                          className={`iconfont iconDelete ${classes.redColorIcon}`}
                        ></i>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                count={total}
                rowsPerPageOptions={[10]}
                rowsPerPage={10}
                onChangePage={(event, page) => setPage(page)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      {open && (
        <ActionDialog
          open={open}
          onClose={handleClose}
          info="Complete this transaction?"
          handleConfirm={(repairedBy) =>
            handleChangeState(repairedBy, transactionId, index)
          }
        />
      )}
      {moveHistoryOpen && (
        <MoveHistory
          open={moveHistoryOpen}
          onClose={() => setMoveHistoryOpen(false)}
          transaction={transaction}
        />
      )}
      {deleteOpen && (
        <ActionDialog
          open={deleteOpen}
          onClose={() => {
            setDeleteOpen(false);
          }}
          info="Delete this transaction?"
          handleConfirm={() => handleDelete(transactionId)}
        />
      )}
      {dialogOpen && (
        <MoveDialog
          open={dialogOpen}
          onClose={handleMoveClose}
          id={transactionId}
        ></MoveDialog>
      )}
      <div id={"barcode"} className={classes.barcodeWrapper}>
        <SimpleBarcode value={barcodeReference} height={18} width={1.5} />
        <div className={classes.barcodeTextWrapper}>
          <div className={classes.barcodeTextLeft}>
            <span className={classes.barcodeTextStore}>{barcodeStore}</span>
          </div>
          <div className={classes.barcodeTextRight}>
            <div className={classes.barcodeText}>{barcodeCustomerName}</div>
            <div
              className={`${classes.barcodeText} ${
                passcode === "" || passcode === "-" || passcode === "N/A"
                  ? classes.barcodeDisplayNone
                  : classes.barcodeDisplayBlock
              }`}
            >
              {passcode}
            </div>
          </div>
        </div>
        <div className={classes.issueWrapper}>
          <span className={classes.barcodeText}>{issue}</span>
        </div>
      </div>
    </>
  );
});

export default Transaction;
