import React, { useEffect, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Route, Switch, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import type { CognitoUser } from "@aws-amplify/auth";
import PermissionCheck from "common/PermissionCheck";
import {
  Transaction,
  History,
  Detail,
  Employee,
  Store,
  New,
  NavBar,
  SideBar,
  SignIn,
} from "component";
import { globalContext } from "context/global";
import { getEmployeeByIdOrCognitoId } from "service/api";
import Collection from "component/Transaction/Collection";

const App: React.FC = () => {
  const history = useHistory();
  const {
    roles,
    setCurrentStoreId,
    setStores,
    setRoles,
    setCurrentEmployeeId,
  } = useContext(globalContext);

  useEffect(() => {
    (async () => {
      try {
        const currentSession = await Auth.currentSession();
        const refreshToken = currentSession.getRefreshToken();
        const cognitoUser =
          (await Auth.currentAuthenticatedUser()) as CognitoUser;
        cognitoUser.refreshSession(refreshToken, async (err, session) => {
          if (err) {
            Auth.signOut();
            setRoles([]);
            history.replace("/signin");
          } else {
            const payload = session.getAccessToken().decodePayload();
            const roles = payload["cognito:groups"] as string[];
            const sub = payload.sub;

            const { data, error } = await getEmployeeByIdOrCognitoId(
              `cognito_${sub}`
            );
            if (data) {
              setCurrentEmployeeId(data.id);
              const prevStoreId = localStorage.getItem("storeId");
              if (prevStoreId) {
                setCurrentStoreId(prevStoreId);
              } else {
                setCurrentStoreId(data.stores?.[0]?.id ?? "");
              }
              setRoles(roles);
            }
            if (error) {
              localStorage.removeItem("storeId");
              if (roles.includes("OWNER")) {
                setCurrentStoreId("");
                setRoles(roles);
                history.replace("/employee");
              } else {
                Auth.signOut();
                history.replace("/signin");
              }
            }
          }
        });
      } catch (e) {
        localStorage.removeItem("storeId");
        Auth.signOut();
        setRoles([]);
        history.replace("/signin");
      }
    })();
  }, [history, setCurrentStoreId, setRoles, setStores, setCurrentEmployeeId]);

  return (
    <>
      <NavBar />
      <main
        style={{
          height: "calc(100vh - 60px)",
          width: "calc(100vw - 76px)",
          marginTop: "60px",
          marginLeft: "76px",
        }}
      >
        {roles === void 0 ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {roles.length !== 0 && <SideBar />}
            <Switch>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/transaction" component={Transaction} />
              <Route exact path="/history" component={History} />
              <Route exact path="/collection" component={Collection} />
              <Route exact path="/transaction/new" component={New} />
              <Route exact path="/transaction/:id" component={Detail} />
              <PermissionCheck requiredRoles={["OWNER"]}>
                <Route exact path="/employee" component={Employee} />
                <Route exact path="/store" component={Store} />
              </PermissionCheck>
            </Switch>
          </>
        )}
      </main>
    </>
  );
};

export default App;
