import React, { useState, useContext, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import { moveTransactionById } from "service/api";
import { globalContext } from "context/global";

interface MoveDialogProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const MoveDialog: React.FC<MoveDialogProps> = ({ id, open, onClose }) => {
  const { currentEmployeeId, employees } = useContext(globalContext);
  const dispatch = useSnackbarDispatch();
  const [storeId, setStoreId] = useState<string>("");

  const handleConfirm = useCallback(async () => {
    try {
      await moveTransactionById(id, storeId);
      onClose();
    } catch (e) {
      dispatch({
        type: SET_SNACKBAR_OPEN,
        message: e.message ?? "Error: can't move the transaction",
        variant: "error",
      });
    }
  }, [dispatch, id, onClose, storeId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          <div>
            Move Transaction To
            <Select
              value={storeId}
              onChange={(event) => setStoreId(event.target.value as string)}
              style={{ marginLeft: "1rem" }}
            >
              {employees
                .find((e) => e.id === currentEmployeeId)
                ?.stores?.map((store) => {
                  return (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={!storeId} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveDialog;
