import React, { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { useStyles } from "./model.style";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import { createStore, getStores } from "service/api";
import { globalContext } from "context/global";
interface CreateStoreProps {
  isShowModal: boolean;
  closeModal: () => void;
}

const CreateStoreSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  companyName: Yup.string().required("Company name is required"),
  warranty: Yup.string().required("Warranty is required"),
  contact: Yup.string().required("Contact number is required"),
  address: Yup.string().required("address number is required"),
  suburb: Yup.string().required("suburb number is required"),
  state: Yup.string().required("state number is required"),
  postcode: Yup.string().required("postcode number is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("email number is required"),
  abn: Yup.string().required("ABN number is required")
});

const StoreModal: React.FC<CreateStoreProps> = props => {
  const classes = useStyles();
  const dispatch = useSnackbarDispatch();
  const { setStores } = useContext(globalContext);
  const { isShowModal, closeModal } = props;

  return (
    <Modal
      disableAutoFocus
      open={isShowModal}
      onClose={closeModal}
      className={classes.modal}
    >
      <Formik
        initialValues={{
          name: "",
          companyName: "",
          contact: "",
          abn: "",
          warranty: "",
          address: "",
          suburb: "",
          state: "",
          postcode: "",
          email: ""
        }}
        validationSchema={CreateStoreSchema}
        onSubmit={async (values, actions) => {
          try {
            const { data, error } = await createStore({
              ...values,
              warranty: +values.warranty
            });

            if (data) {
              dispatch({
                type: SET_SNACKBAR_OPEN,
                message: "Store created successfully",
                variant: "success"
              });
              actions.resetForm();
              const { data } = await getStores({
                page: 0,
                perPage: 1000
              });
              data && setStores(data.data);
              actions.resetForm();
              closeModal();
            }

            if (error) {
              dispatch({
                type: SET_SNACKBAR_OPEN,
                message: error,
                variant: "error"
              });
            }
          } catch (e) {
            dispatch({
              type: SET_SNACKBAR_OPEN,
              message: e.message ?? "",
              variant: "error"
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched, values, status, isSubmitting, handleChange }) => (
          <Form className={classes.form}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Name</InputLabel>
                  <Input
                    onChange={handleChange}
                    fullWidth
                    value={values.name}
                    id="name"
                    name="name"
                  />
                </FormControl>
                {errors.name && touched.name && (
                  <div className={classes.error}>{errors.name}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Company Name</InputLabel>
                  <Input
                    onChange={handleChange}
                    fullWidth
                    value={values.companyName}
                    id="companyName"
                    name="companyName"
                  />
                </FormControl>
                {errors.companyName && touched.companyName && (
                  <div className={classes.error}>{errors.companyName}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Contact number</InputLabel>
                  <Input
                    id="contact"
                    name="contact"
                    value={values.contact}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.contact && touched.contact && (
                  <div className={classes.error}>{errors.contact}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Warranty Time</InputLabel>
                  <Input
                    id="warranty"
                    name="warranty"
                    value={values.warranty}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.warranty && touched.warranty && (
                  <div className={classes.error}>{errors.warranty}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>ABN number</InputLabel>
                  <Input
                    id="abn"
                    name="abn"
                    value={values.abn}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.abn && touched.abn && (
                  <div className={classes.error}>{errors.abn}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Address</InputLabel>
                  <Input
                    id="address"
                    name="address"
                    value={values.address}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.address && touched.address && (
                  <div className={classes.error}>{errors.address}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Suburb</InputLabel>
                  <Input
                    id="suburb"
                    name="suburb"
                    value={values.suburb}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.suburb && touched.suburb && (
                  <div className={classes.error}>{errors.suburb}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>State</InputLabel>
                  <Input
                    id="state"
                    name="state"
                    value={values.state}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.state && touched.state && (
                  <div className={classes.error}>{errors.state}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Postcode</InputLabel>
                  <Input
                    id="postcode"
                    name="postcode"
                    value={values.postcode}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.postcode && touched.postcode && (
                  <div className={classes.error}>{errors.postcode}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel>Email</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    value={values.email}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.email && touched.email && (
                  <div className={classes.error}>{errors.email}</div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              className={classes.modalAction}
            >
              <Grid item xs={5}>
                <Grid container justify="space-between">
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default StoreModal;
