import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles<Theme, { canSelect: boolean }>(theme => ({
  cellWrapper: props => ({
    "&:hover": {
      cursor: props.canSelect ? "pointer" : "default",
      backgroundColor: props.canSelect ? "#eeeeee" : "none"
    },
    minWidth: 100,
    minHeight: 10
  })
}));
