import React, { useContext } from "react";
import { Modal, Input } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useStyles,
  CssTextField,
  SaveButton,
  CancelButton,
} from "./modal.style";
import { globalContext } from "context/global";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import { createEmployee, getEmployees } from "service/api";
import type { Role, Gender, BaseTemplate } from "type";
import { usePermission } from "hook";
interface CreateEmployeeProps {
  isShowModal: boolean;
  closeModal: () => void;
}

const CreateEmployeeSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  role: Yup.string().required("Role is required"),
  password: Yup.string().required("Password is required"),
});

const EmployeeModal: React.FC<CreateEmployeeProps> = (props) => {
  const classes = useStyles();
  const { setEmployees, availableRoles } = useContext(globalContext);
  const dispatch = useSnackbarDispatch();
  const { isShowModal, closeModal } = props;
  const hasOwnerPermisson = usePermission(["OWNER"]);
  return (
    <Modal
      disableAutoFocus
      open={isShowModal}
      onClose={closeModal}
      className={classes.modal}
    >
      <Formik
        initialValues={{
          username: "",
          firstName: "",
          lastName: "",
          gender: "MALE" as Gender,
          role: "USER" as Role,
          password: "",
          birthday: "2021-08-30",
          email: "info@elephone.com.au",
          tfn: "123456",
          contact: "12345678",
        }}
        validationSchema={CreateEmployeeSchema}
        onSubmit={async (values, actions) => {
          const { data: empData, error: empError } = await createEmployee(
            values
          );
          const { data } = await getEmployees();
          data && setEmployees(data.data);

          dispatch({
            type: SET_SNACKBAR_OPEN,
            message: empData ? "Employee created successfully!" : empError,
            variant: empData ? "success" : "error",
          });

          actions.setSubmitting(false);
          actions.resetForm();
          closeModal();
        }}
      >
        {({ errors, touched, values, status, isSubmitting, handleChange }) => (
          <Form className={classes.form}>
            <span className={classes.editTitle}>Staff Details</span>
            <Input
              classes={{
                root: classes.inputRoot,
                input: classes.input,
              }}
              disableUnderline={true}
              placeholder="User Name"
              value={values.username}
              id="username"
              name="username"
              onChange={handleChange}
            />
            {errors.username && touched.username && (
              <div className={classes.error}>{errors.username}</div>
            )}
            {hasOwnerPermisson ? (
              <Autocomplete
                id="combo-box-demo"
                options={availableRoles}
                getOptionLabel={(option) => option.displayName}
                className={classes.select}
                // eslint-disable-next-line
                onChange={(event: any, newValue: BaseTemplate | null) => {
                  if (newValue != null) {
                    values.role = (newValue.key as Role) || null;
                  }
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    className={classes.margin}
                    label="Role"
                    variant="outlined"
                    id="custom-css-outlined-input"
                  />
                )}
              />
            ) : (
              <Input
                classes={{
                  root: classes.inputRoot,
                  input: classes.input,
                }}
                disableUnderline={true}
                value={values.role}
                onChange={handleChange}
                disabled={true}
                id="role"
                name="role"
              />
            )}
            {errors.role && touched.role && (
              <div className={classes.error}>{errors.role}</div>
            )}
            <Input
              classes={{
                root: classes.inputRoot,
                input: classes.input,
              }}
              disableUnderline={true}
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
              id="firstName"
              name="firstName"
            />
            {errors.firstName && touched.firstName && (
              <div className={classes.error}>{errors.firstName}</div>
            )}
            <Input
              classes={{
                root: classes.inputRoot,
                input: classes.input,
              }}
              disableUnderline={true}
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
              id="lastName"
              name="lastName"
            />
            {errors.lastName && touched.lastName && (
              <div className={classes.error}>{errors.lastName}</div>
            )}
            <Input
              classes={{
                root: classes.inputRoot,
                input: classes.input,
              }}
              disableUnderline={true}
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              type="password"
              id="password"
              name="password"
            />
            {errors.password && touched.password && (
              <div className={classes.error}>{errors.password}</div>
            )}
            <div className={classes.operateButtonWrapper}>
              <CancelButton
                variant="contained"
                color="primary"
                disableRipple
                className={classes.buttonMargin}
                onClick={closeModal}
              >
                Cancel
              </CancelButton>
              <SaveButton
                variant="contained"
                color="primary"
                disableRipple
                className={classes.buttonMargin}
                disabled={isSubmitting}
                type="submit"
              >
                Save
              </SaveButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EmployeeModal;
