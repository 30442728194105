import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useStyles } from "./Signin.style";
import Logo from "asset/logo.png";
import { useHistory } from "react-router-dom";
import { globalContext } from "context/global";
import { getEmployeeByIdOrCognitoId } from "service/api";
const SignInSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const SignIn: React.FC = () => {
  const { setCurrentStoreId, setRoles, setCurrentEmployeeId } =
    useContext(globalContext);
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <img className={classes.avatar} src={Logo} alt="" />
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={SignInSchema}
            onSubmit={async ({ username, password }, actions) => {
              actions.setErrors({
                username: "",
                password: "",
              });
              actions.setStatus({
                msg: "",
              });

              try {
                const res = await Auth.signIn(username, password);
                const payload = res.signInUserSession.accessToken.payload;
                const roles = payload["cognito:groups"] as string[];
                const sub = payload.sub;
                const { data, error } = await getEmployeeByIdOrCognitoId(
                  `cognito_${sub}`
                );
                actions.setSubmitting(false);
                if (data) {
                  setCurrentEmployeeId(data.id);
                  const prevStoreId = localStorage.getItem("storeId");
                  if (prevStoreId) {
                    setCurrentStoreId(prevStoreId);
                  } else {
                    if (data.defaultStore) {
                      setCurrentStoreId(data.defaultStore.id);
                      localStorage.setItem("storeId", data.defaultStore.id);
                    } else {
                      setCurrentStoreId(data.stores?.[0]?.id ?? "");
                    }
                  }
                  setRoles(roles);
                  history.replace("/transaction");
                }
                if (error) {
                  localStorage.removeItem("storeId");
                  if (roles.includes("OWNER")) {
                    setCurrentStoreId("");
                    setRoles(roles);
                    history.replace("/employee");
                  } else {
                    Auth.signOut();
                    history.replace("/signin");
                  }
                }
              } catch (e) {
                localStorage.removeItem("storeId");
                actions.setSubmitting(false);
                actions.setStatus({ msg: e.message });
              }
            }}
          >
            {({ errors, touched, status, isSubmitting, handleChange }) => (
              <Form className={classes.form}>
                <TextField
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                />
                {errors.username && touched.username && (
                  <div className={classes.error}>{errors.username}</div>
                )}
                <TextField
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {errors.password && touched.password && (
                  <div className={classes.error}>{errors.password}</div>
                )}
                {status && status.msg && (
                  <div className={classes.error}>{status.msg}</div>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // disabled={isSubmitting || isMobile}
                  disabled={isSubmitting}
                  className={classes.submit}
                >
                  {/* {isMobile ? "Please use a desktop to login" : "Sign in"} */}
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default SignIn;
