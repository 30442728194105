import React, { useState, useEffect, useContext, useCallback } from "react";
import { Grid, Input } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { useStyles, CssTextField } from "./history.style";
import { useHistory } from "react-router-dom";
import { globalContext } from "context/global";
import {
  getTransactions,
  sendEmail,
  updateTransactionStatusById,
  deleteTransactionById,
} from "service/api";
import { useDebounce } from "hook";
import type { TransactionDTO } from "type";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import PermissionCheck from "common/PermissionCheck";
import FlagIcon from "@material-ui/icons/Flag";
import ActionDialog from "../Transaction/ActionDialog";
import config from "config";
import MoveHistory from "../Transaction/MoveHistory";

type SearchType = "reference" | "name" | "contact";

const History: React.FC = React.memo(() => {
  const { currentStoreId, currentEmployeeId } = useContext(globalContext);
  const dispatch = useSnackbarDispatch();
  const [page, setPage] = useState<number>(0);
  const [includeAll] = useState<boolean>(false);
  const [type, setType] = useState<SearchType>("contact");
  const [filter, setFilter] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [transactions, setTransactions] = useState<TransactionDTO[]>([]);
  const [count, setCount] = useState<number>(0);
  const classes = useStyles();
  const history = useHistory();
  const debouncedFilter = useDebounce(filter);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>("");
  const [hasWarranty, setHasWarranty] = useState<boolean>(false);
  const [showMoved, setShowMoved] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [returnOpen, setReturnOpen] = useState<boolean>(false);
  const [moveHistoryOpen, setMoveHistoryOpen] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<TransactionDTO | null>(null);
  const [repairedByValue, setRepairedByValue] = useState<string>("");

  useEffect(() => {
    if (currentStoreId !== void 0) {
      (async () => {
        const params: Record<string, unknown> = {
          page,
          perPage: 10,
          status: "FINALISED",
        };

        if (currentStoreId && !includeAll) {
          params.storeId = currentStoreId;
        }
        if (type === "reference" && debouncedFilter !== "") {
          params.reference = debouncedFilter;
        }
        if (type === "name" && debouncedFilter !== "") {
          params.customerName = debouncedFilter;
        }
        if (type === "contact" && debouncedFilter !== "") {
          params.contact = debouncedFilter;
        }
        if (hasWarranty) {
          params.hasWarranty = true;
        }
        if (showMoved) {
          params.showCreatedAt = true;
        }
        const { data } = await getTransactions(params);

        if (data) {
          setTransactions(data.data);
          setTotal(data.total);
        }
      })();
    }
  }, [
    currentStoreId,
    debouncedFilter,
    page,
    type,
    count,
    hasWarranty,
    showMoved,
    includeAll,
  ]);

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteTransactionById(id);
      setTransactions((ts) => ts.filter((t) => t.id !== id));
      dispatch({
        type: SET_SNACKBAR_OPEN,
        variant: "success",
        message: "Delete Successfully!",
      });
      setDeleteOpen(false);
    },
    [dispatch]
  );

  const handleClose = useCallback(async () => {
    setDeleteOpen(false);
  }, []);

  const handleChangeState = useCallback(
    async (repairedBy: string, id: string, index: number) => {
      const { data } = await updateTransactionStatusById(
        id,
        "TO_BE_COLLECTED",
        currentEmployeeId,
        repairedBy
      );
      if (data) {
        setTransactions((ts) =>
          ts.filter((t, i) => {
            return index !== i;
          })
        );
        dispatch({
          type: SET_SNACKBAR_OPEN,
          variant: "success",
          message: "Status changed successfully",
        });
      }
      setReturnOpen(false);
    },
    [currentEmployeeId, dispatch]
  );
  const queryFieldList: string[] = [
    "Contact Number",
    "Reference",
    "Customer Name",
  ];
  return (
    <Grid container className={classes.wrapper}>
      <span className={classes.title}>History</span>
      <div className={classes.queryWrapper}>
        <Autocomplete
          id="combo-box-demo"
          options={queryFieldList}
          getOptionLabel={(option) => option}
          className={classes.select}
          // eslint-disable-next-line
          onChange={(event: any, newValue: string | null) => {
            if (newValue === "Contact Number") {
              setType("contact" as SearchType);
            } else if (newValue === "Reference") {
              setType("reference" as SearchType);
            } else if (newValue === "Customer Name") {
              setType("name" as SearchType);
            } else {
            }
          }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              className={classes.margin}
              label="Search by"
              variant="outlined"
              id="custom-css-outlined-input"
            />
          )}
        />
        <Input
          id="filter"
          value={filter}
          onChange={(event) => setFilter(event.target.value as string)}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          disableUnderline={true}
          placeholder="Search"
        />
        <div className={classes.checkboxWrapper}>
          <div
            className={hasWarranty ? classes.checkboxActive : classes.checkbox}
            onClick={() => {
              setHasWarranty(!hasWarranty);
            }}
          />
          <span className={classes.checkboxText}>Has Warranty</span>
        </div>
        <div className={classes.checkboxWrapper}>
          <div
            className={showMoved ? classes.checkboxActive : classes.checkbox}
            onClick={() => setShowMoved((c) => !c)}
          />
          <span className={classes.checkboxText}>Moved Transaction</span>
        </div>
      </div>
      <Table size="small" classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>Warranty</TableCell>
            <TableCell className={classes.headerTitle}>Customer</TableCell>
            <TableCell className={classes.headerTitle}>Contact</TableCell>
            <TableCell className={classes.headerTitle}>PickUp Date</TableCell>
            <TableCell className={classes.headerTitle}>Device Model</TableCell>
            <TableCell className={classes.headerTitle}>Finalised By</TableCell>
            <PermissionCheck requiredRoles={["ADMIN"]}>
              <TableCell className={classes.headerTitle}>Action</TableCell>
            </PermissionCheck>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((transaction) => {
            return (
              <TableRow
                hover
                key={transaction.id}
                classes={{
                  root: classes.tableRowRoot,
                }}
                onClick={(event) =>
                  history.push({
                    pathname: `/transaction/${transaction.id}`,
                    state: { transaction },
                  })
                }
              >
                <TableCell>
                  <FlagIcon
                    color={
                      transaction.warrantyHistories.length > 0
                        ? "secondary"
                        : "disabled"
                    }
                  />
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.customerName}
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.contact}
                </TableCell>
                {new Date((transaction?.pickupTime || "").replace(/-/g, "/")) >
                new Date() ? (
                  <TableCell
                    className={classes.tableContent}
                    classes={{ root: classes.tableCellRoot }}
                  >
                    {transaction.pickupTime}
                  </TableCell>
                ) : (
                  <TableCell
                    className={`${classes.tableContent} ${classes.redColorFont}`}
                    classes={{ root: classes.tableCellRoot }}
                  >
                    {transaction.pickupTime}
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.device}
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.finalisedBy.firstName}{" "}
                  {transaction.finalisedBy.lastName}
                </TableCell>
                <PermissionCheck requiredRoles={["ADMIN"]}>
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableCellRoot }}
                  >
                    <div className={classes.buttonWrapper}>
                      <div
                        title="Email"
                        className={classes.buttonItem}
                        onClick={async (event) => {
                          event.stopPropagation();
                          const { error } = await sendEmail(
                            transaction.id,
                            "confirmation"
                          );
                          setCount((c) => c + 1);

                          if (error) {
                            dispatch({
                              type: SET_SNACKBAR_OPEN,
                              message: error,
                              variant: "error",
                            });
                          } else {
                            dispatch({
                              type: SET_SNACKBAR_OPEN,
                              message: "email sent sucessfully",
                              variant: "success",
                            });
                          }
                        }}
                      >
                        <i
                          className={`iconfont iconEmail1 ${classes.greyColorIcon}`}
                        ></i>
                      </div>

                      <div
                        title="Download"
                        className={classes.buttonItem}
                        onClick={async (event) => {
                          event.stopPropagation();
                          window.open(
                            `${config.baseUrl}/pdf/${transaction.id}/confirmation`,
                            "_blank"
                          );
                        }}
                      >
                        <i
                          className={`iconfont iconPrint ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      <div
                        title="History"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setMoveHistoryOpen(true);
                          setTransaction(transaction);
                        }}
                      >
                        <i
                          className={`iconfont iconRecord ${
                            transaction.transactionTransitions.length > 1
                              ? classes.greyColorIcon
                              : classes.mainColorIcon
                          }`}
                        ></i>
                      </div>
                      <div
                        title="Back"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setIndex(index);
                          setTransactionId(transaction.id);
                          setRepairedByValue(transaction.repairedBy);
                          setReturnOpen(true);
                        }}
                      >
                        <i
                          className={`iconfont iconBack ${classes.greyColorIcon}`}
                        ></i>
                      </div>
                      <div
                        title="Delete"
                        className={classes.buttonItem}
                        onClick={(event) => {
                          event.stopPropagation();
                          setDeleteOpen(true);
                          setTransactionId(transaction.id);
                        }}
                      >
                        <i
                          className={`iconfont iconDelete ${classes.redColorIcon}`}
                        ></i>
                      </div>
                    </div>
                  </TableCell>
                </PermissionCheck>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              count={total}
              rowsPerPageOptions={[10]}
              rowsPerPage={10}
              onChangePage={(event, page) => setPage(page)}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {moveHistoryOpen && (
        <MoveHistory
          open={moveHistoryOpen}
          onClose={() => setMoveHistoryOpen(false)}
          transaction={transaction}
        />
      )}
      {deleteOpen && (
        <ActionDialog
          open={deleteOpen}
          onClose={handleClose}
          info="Delete this transaction?"
          handleConfirm={() => handleDelete(transactionId)}
        />
      )}
      {returnOpen && (
        <ActionDialog
          open={returnOpen}
          onClose={() => {
            setReturnOpen(false);
          }}
          info="Move this transaction to be collected?"
          handleConfirm={() =>
            handleChangeState(repairedByValue, transactionId, index)
          }
        />
      )}
    </Grid>
  );
});

export default History;
