import {
  Checkbox,
  FormControlLabel,
  withStyles,
  Theme,
} from "@material-ui/core";
import { FieldHookConfig, useField } from "formik";

import React from "react";

type MyChekcboxProps = {
  label: string;
} & FieldHookConfig<Record<string, unknown>>;

const MyFormControlLabel = withStyles((theme: Theme) => ({
  root: {
    "& span.MuiTypography-body1": {
      fontSize: 14,
      fontFamily: "Helvetica",
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        fontSize: 14,
      },
    },
  },
}))(FormControlLabel);

const MyCheckbox: React.FC<MyChekcboxProps> = ({
  label,
  disabled,
  ...props
}) => {
  const [field] = useField<Record<string, unknown>>(props);
  return (
    <MyFormControlLabel
      {...field}
      control={<Checkbox color="primary" />}
      label={label}
      disabled={disabled}
    />
  );
};

export default MyCheckbox;
