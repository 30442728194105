import React, { useState, useEffect, useContext, useCallback } from "react";
import { Grid, Input } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { useStyles, CssTextField } from "./collection.style";
import { useHistory } from "react-router-dom";
import { globalContext } from "context/global";
import { getTransactions, updateTransactionStatusById } from "service/api";
import { useDebounce } from "hook";
import type { TransactionDTO } from "type";
import { deleteTransactionById } from "service/api";
import { useSnackbarDispatch, SET_SNACKBAR_OPEN } from "context/snackbar";
import ActionDialog from "../Transaction/ActionDialog";
import Modal from "@material-ui/core/Modal";

type SearchType = "reference" | "name" | "contact";

const Collection: React.FC = React.memo(() => {
  const { currentStoreId, currentEmployeeId } = useContext(globalContext);
  const dispatch = useSnackbarDispatch();
  const [page, setPage] = useState<number>(0);
  const [includeAll] = useState<boolean>(false);
  const [type, setType] = useState<SearchType>("contact");
  const [filter, setFilter] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [transactions, setTransactions] = useState<TransactionDTO[]>([]);
  const [count] = useState<number>(0);
  const classes = useStyles();
  const history = useHistory();
  const debouncedFilter = useDebounce(filter);
  const [transactionId, setTransactionId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [returnOpen, setReturnOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [showUnsigned, setShowUnsigned] = useState<boolean>(false);
  const [repairedByValue, setRepairedByValue] = useState<string>("");

  const handleChangeState = useCallback(
    async (repairedBy: string, id: string, index: number) => {
      const { data } = await updateTransactionStatusById(
        id,
        "FINALISED",
        currentEmployeeId,
        repairedBy
      );
      if (data) {
        setTransactions((ts) =>
          ts.filter((t, i) => {
            return index !== i;
          })
        );
        dispatch({
          type: SET_SNACKBAR_OPEN,
          variant: "success",
          message: "Status changed successfully",
        });
      }
      setOpen(false);
    },
    [currentEmployeeId, dispatch]
  );

  const handleReturnChangeState = useCallback(
    async (repairedBy: string, id: string, index: number) => {
      const { data } = await updateTransactionStatusById(
        id,
        "RECEIVED",
        currentEmployeeId,
        repairedBy
      );
      if (data) {
        setTransactions((ts) =>
          ts.filter((t, i) => {
            return index !== i;
          })
        );
        dispatch({
          type: SET_SNACKBAR_OPEN,
          variant: "success",
          message: "Status changed successfully",
        });
      }
      setReturnOpen(false);
    },
    [currentEmployeeId, dispatch]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteTransactionById(id);
      setTransactions((ts) => ts.filter((t) => t.id !== id));
      dispatch({
        type: SET_SNACKBAR_OPEN,
        variant: "success",
        message: "Delete Successfully!",
      });
      setDeleteOpen(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentStoreId !== void 0) {
      (async () => {
        const params: Record<string, unknown> = {
          page,
          perPage: 10,
          status: "TO_BE_COLLECTED",
        };

        if (currentStoreId && !includeAll) {
          params.storeId = currentStoreId;
        }
        if (type === "reference" && debouncedFilter !== "") {
          params.reference = debouncedFilter;
        }
        if (type === "name" && debouncedFilter !== "") {
          params.customerName = debouncedFilter;
        }
        if (type === "contact" && debouncedFilter !== "") {
          params.contact = debouncedFilter;
        }
        const { data } = await getTransactions(params);

        if (data) {
          setTransactions(data.data);
          setTotal(data.total);
        }
      })();
    }
  }, [currentStoreId, debouncedFilter, page, type, count, includeAll]);
  const queryFieldList: string[] = [
    "Contact Number",
    "Reference",
    "Customer Name",
  ];
  return (
    <Grid container className={classes.wrapper}>
      <span className={classes.title}>Collecting</span>
      <div className={classes.queryWrapper}>
        <Autocomplete
          id="combo-box-demo"
          options={queryFieldList}
          getOptionLabel={(option) => option}
          className={classes.select}
          // eslint-disable-next-line
          onChange={(event: any, newValue: string | null) => {
            if (newValue === "Contact Number") {
              setType("contact" as SearchType);
            } else if (newValue === "Reference") {
              setType("reference" as SearchType);
            } else if (newValue === "Customer Name") {
              setType("name" as SearchType);
            } else {
            }
          }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              className={classes.margin}
              label="Search by"
              variant="outlined"
              id="custom-css-outlined-input"
            />
          )}
        />
        <Input
          id="filter"
          value={filter}
          onChange={(event) => setFilter(event.target.value as string)}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          disableUnderline={true}
          placeholder="Search"
        />
      </div>
      <Table size="small" classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerTitle}>Customer</TableCell>
            <TableCell className={classes.headerTitle}>Contact</TableCell>
            <TableCell className={classes.headerTitle}>Device Model</TableCell>
            <TableCell className={classes.headerTitle}>Due Date</TableCell>
            <TableCell className={classes.headerTitle}>Created By</TableCell>
            <TableCell className={classes.headerTitle}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((transaction, index) => {
            return (
              <TableRow
                hover
                key={transaction.id}
                classes={{
                  root: classes.tableRowRoot,
                }}
                onClick={(event) =>
                  history.push({
                    pathname: `/transaction/${transaction.id}`,
                    state: { transaction },
                  })
                }
              >
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.customerName}
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.contact}
                </TableCell>
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {/* {transaction.finalisedBy} */}
                  {transaction.device}
                </TableCell>
                {new Date((transaction?.pickupTime || "").replace(/-/g, "/")) >
                new Date() ? (
                  <TableCell
                    className={classes.tableContent}
                    classes={{ root: classes.tableCellRoot }}
                  >
                    {transaction.pickupTime}
                  </TableCell>
                ) : (
                  <TableCell
                    className={`${classes.tableContent} ${classes.redColorFont}`}
                    classes={{ root: classes.tableCellRoot }}
                  >
                    {transaction.pickupTime}
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableContent}
                  classes={{ root: classes.tableCellRoot }}
                >
                  {transaction.createdBy.firstName}
                  {transaction.createdBy.lastName}
                </TableCell>

                <TableCell
                  align="left"
                  classes={{ root: classes.tableCellRoot }}
                >
                  <div className={classes.buttonWrapper}>
                    <div
                      title="Next"
                      className={classes.buttonItem}
                      onClick={(event) => {
                        if ((transaction.confSignature ?? "").length === 0) {
                          event.stopPropagation();
                          setShowUnsigned(true);
                        } else {
                          event.stopPropagation();
                          setIndex(index);
                          setTransactionId(transaction.id);
                          setRepairedByValue(transaction.repairedBy);
                          setOpen(true);
                        }
                      }}
                    >
                      <i
                        className={`iconfont iconDownload ${
                          (transaction.confSignature ?? "").length > 0
                            ? classes.mainColorIcon
                            : classes.greyColorIcon
                        }`}
                      ></i>
                    </div>
                    <div
                      title="Back"
                      className={classes.buttonItem}
                      onClick={(event) => {
                        event.stopPropagation();
                        setIndex(index);
                        setTransactionId(transaction.id);
                        setRepairedByValue(transaction.repairedBy);
                        setReturnOpen(true);
                      }}
                    >
                      <i
                        className={`iconfont iconBack ${classes.greyColorIcon}`}
                      ></i>
                    </div>
                    <div
                      title="Delete"
                      className={classes.buttonItem}
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteOpen(true);
                        setTransactionId(transaction.id);
                      }}
                    >
                      <i
                        className={`iconfont iconDelete ${classes.redColorIcon}`}
                      ></i>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              count={total}
              rowsPerPageOptions={[10]}
              rowsPerPage={10}
              onChangePage={(event, page) => setPage(page)}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {open && (
        <ActionDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          info="Finalise this transaction?"
          handleConfirm={() =>
            handleChangeState(repairedByValue, transactionId, index)
          }
        />
      )}
      {returnOpen && (
        <ActionDialog
          open={returnOpen}
          onClose={() => {
            setReturnOpen(false);
          }}
          info="Move this transaction to in progress?"
          handleConfirm={() =>
            handleReturnChangeState(repairedByValue, transactionId, index)
          }
        />
      )}
      {deleteOpen && (
        <ActionDialog
          open={deleteOpen}
          onClose={() => {
            setDeleteOpen(false);
          }}
          info="Delete this transaction?"
          handleConfirm={() => handleDelete(transactionId)}
        />
      )}
      <Modal
        open={showUnsigned}
        onClose={() => {
          setShowUnsigned(false);
        }}
        className={classes.showUnsignedModelWrapper}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.showUnsignedModel}>
          <h2 className={classes.unsignedModelTitle}>Missing signature</h2>
          <p className={classes.unsignedModelDesc}>
            Please add signature first.
          </p>
        </div>
      </Modal>
    </Grid>
  );
});

export default Collection;
