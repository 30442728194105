import Axios, { AxiosError } from "axios";
import { Auth } from "aws-amplify";
import type { CognitoUser } from "@aws-amplify/auth";
import config from "config";
import {
  BaseResponse,
  Pagination,
  StoreDTO,
  EmployeeDTO,
  IncomeDTO,
  TransactionDTO,
  WorkHistoryDTO,
  CreateEmployeeDTO,
  UpdateEmployeeDTO,
  CreateTransactionDTO,
  CreateStoreDTO,
  CreateIncomeDTO,
  CreateCommentDTO,
  CommentDTO,
  UpdateCommentDTO,
  BaseTemplate,
  CreateWarrantyDTO,
  WarrantyDTO,
  UpdateTransactionDTO,
} from "type";

export const axios = Axios.create({
  baseURL: config.baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(async (config) => {
  return new Promise(async (resolve) => {
    try {
      const currentSession = await Auth.currentSession();
      const refreshToken = currentSession.getRefreshToken();
      const isValid = currentSession.isValid();
      if (!isValid) {
        const cognitoUser =
          (await Auth.currentAuthenticatedUser()) as CognitoUser;
        cognitoUser.refreshSession(refreshToken, (err, data) => {
          if (err) {
            localStorage.clear();
            Auth.signOut();
            window.location.assign("/signin");
          } else {
            config.headers.Authorization = `Bearer ${data
              .getIdToken()
              .getJwtToken()}`;
            resolve(config);
          }
        });
      } else {
        config.headers.Authorization =
          "Bearer " + currentSession.getAccessToken().getJwtToken();
        resolve(config);
      }
    } catch (e) {
      resolve(config);
    }
  });
});

axios.interceptors.response.use((res) => {
  if (res.status === 401) {
    localStorage.clear();
    Auth.signOut();
    window.location.assign("/signin");
  }

  return res;
});

const errorHandler = (error: AxiosError): string => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
      case 403:
        return `Error: ${
          error.response.data?.message ?? error.response.data?.error
        }`;
      default:
        const err = error.response.data?.message ?? error.response.data?.error;
        if (Array.isArray(err)) {
          return `Error: ${err[0]}`;
        } else {
          return `Errorr: ${err}`;
        }
    }
  } else if (error.request) {
    return `Error: ${error.message}`;
  } else {
    return `Error: ${error.message}`;
  }
};

export const getTransactions = async (params: {
  page?: number;
  perPage?: number;
  status?: string;
  filter?: string;
  storeId?: string;
}): Promise<BaseResponse<Pagination<TransactionDTO[]>>> => {
  try {
    const url = `api/v1/transaction`;
    const { data, headers } = await axios.get<TransactionDTO[]>(url, {
      params,
    });
    return {
      data: {
        data,
        total: +headers["x-total-count"],
      },
    };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getTransactionById = async (
  id: string
): Promise<BaseResponse<TransactionDTO>> => {
  try {
    const url = `api/v1/transaction/${id}`;
    const { data } = await axios.get<TransactionDTO>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const createTransaction = async (
  transaction: CreateTransactionDTO
): Promise<BaseResponse<TransactionDTO>> => {
  try {
    const url = `api/v1/transaction`;
    const { data } = await axios.post<TransactionDTO>(url, transaction);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const updateTransaction = async (
  transaction: UpdateTransactionDTO
): Promise<BaseResponse<TransactionDTO>> => {
  try {
    const url = `api/v1/transaction`;
    const { data } = await axios.put<TransactionDTO>(url, transaction);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const updateTransactionStatusById = async (
  id: string,
  status: string,
  employeeId: string | undefined,
  repairedBy: string
): Promise<BaseResponse<TransactionDTO>> => {
  try {
    const url = `api/v1/transaction/${id}/status`;
    const { data } = await axios.put<TransactionDTO>(url, {
      status,
      updatedBy: employeeId,
      repairedBy,
    });
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const deleteTransactionById = async (
  id: string
): Promise<BaseResponse<{ message: string }>> => {
  try {
    const url = `api/v1/transaction/${id}`;
    const { data } = await axios.delete<{ message: string }>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const finaliseTransactionById = async (
  id: string,
  body: {
    finalisedBy: string;
  }
): Promise<BaseResponse<TransactionDTO>> => {
  try {
    const url = `api/v1/transaction/${id}/finalise`;
    const { data } = await axios.put<TransactionDTO>(url, body);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const createStore = async (
  store: CreateStoreDTO
): Promise<BaseResponse<StoreDTO>> => {
  try {
    const url = `api/v1/store`;
    const { data } = await axios.post<StoreDTO>(url, store);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getStores = async (params: {
  page?: number;
  perPage?: number;
}): Promise<BaseResponse<Pagination<StoreDTO[]>>> => {
  try {
    const url = `api/v1/store`;
    const res = await axios.get<StoreDTO[]>(url, { params });

    return {
      data: {
        data: res.data,
        total: +res.headers["x-total-count"],
      },
    };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getStoreById = async (
  id: string
): Promise<BaseResponse<StoreDTO>> => {
  try {
    const url = `api/v1/store/${id}`;
    const { data } = await axios.get<StoreDTO>(url, {});

    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const updateStore = async (
  store: StoreDTO
): Promise<BaseResponse<StoreDTO>> => {
  try {
    const url = `api/v1/store/${store.id}`;
    const { data } = await axios.put<StoreDTO>(url, store);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const updateStorePassword = async (
  storeId: string,
  newPassword: string
): Promise<BaseResponse<StoreDTO>> => {
  try {
    const url = `api/v1/store/${storeId}/password`;
    const { data } = await axios.put<StoreDTO>(url, newPassword);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const deleteStoreById = async (
  id: string
): Promise<BaseResponse<StoreDTO>> => {
  try {
    const url = `api/v1/store/${id}`;
    const { data } = await axios.delete<StoreDTO>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const createEmployee = async (
  employee: CreateEmployeeDTO
): Promise<BaseResponse<EmployeeDTO>> => {
  try {
    const url = `api/v1/employee`;
    const { data } = await axios.post<EmployeeDTO>(url, employee);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getEmployees = async (params?: {
  storeId?: string;
  page?: number;
  perPage?: number;
}): Promise<BaseResponse<Pagination<EmployeeDTO[]>>> => {
  try {
    const url = `api/v1/employee`;
    const { data, headers } = await axios.get<EmployeeDTO[]>(url, { params });
    return {
      data: {
        data,
        total: +headers["x-total-count"],
      },
    };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getAvailableTransactionStatus = async (): Promise<
  BaseResponse<BaseTemplate[]>
> => {
  try {
    const url = `api/v1/template/status`;
    const { data } = await axios.get<BaseTemplate[]>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getAvailableInspection = async (): Promise<
  BaseResponse<BaseTemplate[]>
> => {
  try {
    const url = `api/v1/template/inspection`;
    const { data } = await axios.get<BaseTemplate[]>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};
export const getAvailableRole = async (): Promise<
  BaseResponse<BaseTemplate[]>
> => {
  try {
    const url = `api/v1/template/role`;
    const { data } = await axios.get<BaseTemplate[]>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getAvailableGenders = async (): Promise<
  BaseResponse<BaseTemplate[]>
> => {
  try {
    const url = `api/v1/template/gender`;
    const { data } = await axios.get<BaseTemplate[]>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getEmployeeById = async (
  id: string
): Promise<BaseResponse<EmployeeDTO>> => {
  try {
    const url = `api/v1/employee/${id}`;
    const { data } = await axios.get<EmployeeDTO>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getEmployeeByIdOrCognitoId = async (
  uniqueId: string
): Promise<BaseResponse<EmployeeDTO>> => {
  try {
    const url = `api/v1/employee/${uniqueId}`;
    const { data } = await axios.get<EmployeeDTO>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const updateEmployee = async (
  employee: UpdateEmployeeDTO
): Promise<BaseResponse<EmployeeDTO>> => {
  try {
    const url = `api/v1/employee`;
    const { data } = await axios.put<EmployeeDTO>(url, employee);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const deleteEmployeeById = async (
  id: string
): Promise<BaseResponse<EmployeeDTO>> => {
  try {
    const url = `api/v1/employee/${id}`;
    const { data } = await axios.delete<EmployeeDTO>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getIncomeByStoreId = async (
  storeId: string,
  params: {
    page?: number;
    perPage?: number;
  }
): Promise<BaseResponse<Pagination<IncomeDTO[]>>> => {
  try {
    const url = `api/v1/income/${storeId}`;
    const { data, headers } = await axios.get<IncomeDTO[]>(url, { params });

    return {
      data: {
        data,
        total: +headers["x-total-count"],
      },
    };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const createIncome = async (
  income: CreateIncomeDTO
): Promise<BaseResponse<IncomeDTO[]>> => {
  try {
    const url = `api/v1/income`;
    const { data } = await axios.post<IncomeDTO[]>(url, income);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const getWorkHistoryByEmployeeId = async (
  employeeId: string,
  params: {
    page?: number;
    perPage?: number;
  }
): Promise<BaseResponse<Pagination<WorkHistoryDTO[]>>> => {
  try {
    const url = `api/v1/workHistory/${employeeId}`;
    const { data, headers } = await axios.get<WorkHistoryDTO[]>(url, {
      params,
    });
    return {
      data: {
        data,
        total: +headers["x-total-count"],
      },
    };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const createComment = async (
  comment: CreateCommentDTO
): Promise<BaseResponse<CommentDTO>> => {
  try {
    const url = `api/v1/transaction/comment`;
    const { data } = await axios.post<CommentDTO>(url, comment);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const updateComment = async (
  updatedComment: UpdateCommentDTO
): Promise<BaseResponse<CommentDTO>> => {
  try {
    const url = `api/v1/transaction/comment`;
    const { data } = await axios.put<CommentDTO>(url, updatedComment);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const deleteCommentById = async (
  id: string
): Promise<BaseResponse<{ message: string }>> => {
  try {
    const url = `api/v1/transaction/comment/${id}`;
    const { data } = await axios.delete<{ message: string }>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const sendEmail = async (
  transactionId: string,
  type: string
): Promise<BaseResponse<{ message: string }>> => {
  try {
    const url = `api/v1/email`;
    const { data } = await axios.post<{ message: string }>(url, {
      transactionId,
      type,
    });
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const moveTransactionById = async (
  transactionId: string,
  storeId: string
): Promise<BaseResponse<TransactionDTO>> => {
  try {
    const url = `api/v1/transaction/${transactionId}/move/${storeId}`;
    const { data } = await axios.put<TransactionDTO>(url);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};

export const createWarranty = async (
  warranty: CreateWarrantyDTO
): Promise<BaseResponse<WarrantyDTO>> => {
  try {
    const url = `api/v1/transaction/warranty`;
    const { data } = await axios.post<WarrantyDTO>(url, warranty);
    return { data };
  } catch (e) {
    return { error: errorHandler(e) };
  }
};
