import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export const useStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    color: "#000000",
  },
  wrapper: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    marginTop: 84,
    marginLeft: 40,
    marginRight: 40,
    flexWrap: "nowrap",
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  formWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      flexDirection: "column",
    },
  },
  topWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    width: 252,
    height: 56,
  },
  topRightWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tewWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  tewItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  tewItemTitle: {
    width: 20,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.primary.main,
    lineHeight: "13px",
  },
  tewItemText: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "13px",
  },
  watergardensWrapper: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  watergardensTitle: {
    fontSize: 14,
    fontFamily: "Helvetica",
    fontWeight: 600,
    color: theme.palette.default.dark,
    lineHeight: "13px",
  },
  watergardensText: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "18px",
  },
  bottomWrapper: {
    marginTop: 32,
  },
  bottomLeftWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  workAuthorisationForm: {
    fontSize: 20,
    fontFamily: "Termina-Bold, Termina",
    fontWeight: "bold",
    color: theme.palette.default.dark,
    lineHeight: "24px",
  },
  workAuthorisationItem: {
    marginTop: 6,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  workAuthorisationTitle: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    fontWeight: 600,
    lineHeight: "16px",
  },
  pickupTimeWrapper: {
    marginLeft: 10,
    marginBottom: 4,
  },
  warrantyPeriodWrapper: {
    marginTop: 13,
  },
  warrantyPeriodText: {
    marginTop: 10,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "16px",
  },
  warrantyPeriodTitle: {
    marginTop: 10,
  },
  workAuthorisationText: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "16px",
  },
  barcodeWrapper: {
    width: "auto",
    height: "auto",
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(42, 179, 231, 0.2)",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainLeftWrapper: {
    width: "47%",
    marginRight: 32,
    display: "flex",
    flexDirection: "column",
  },
  infoTitle: {
    marginTop: 18,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "14px",
    fontWeight: 600,
    display: "block",
  },
  marginTop25: {
    marginTop: 24,
  },
  width6: {
    width: "6%",
  },
  width32: {
    width: 159,
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 121,
    },
  },
  width58: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  infoItemRoot: {
    marginTop: 14,
  },
  infoItem: {
    width: "100%",
    marginTop: 14,
    display: "flex",
    flexDirection: "column",
  },
  infoLabel: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
  },
  requestColor: {
    color: "red",
  },
  inputRoot: {
    marginTop: theme.spacing(1),
    height: 32,
  },
  input: {
    paddingLeft: 14,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.27)",
    borderRadius: 3,
    fontFamily: "Helvetica",
    "&::-webkit-input-placeholder": {
      color: "black",
      opacity: 0.6,
    },
    "&::-moz-placeholder": {
      color: "black",
      opacity: 0.6,
    },
    "&::-moz-placeholde": {
      color: "black",
      opacity: 0.6,
    },
    "&::-ms-input-placeholder": {
      color: "black",
      opacity: 0.6,
    },
  },
  textInput: {
    paddingTop: 10,
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 10,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.27)",
    borderRadius: 3,
    fontFamily: "Helvetica",
    "&::-webkit-input-placeholder": {
      color: "black",
      opacity: 0.6,
    },
    "&::-moz-placeholder": {
      color: "black",
      opacity: 0.6,
    },
    "&::-moz-placeholde": {
      color: "black",
      opacity: 0.6,
    },
    "&::-ms-input-placeholder": {
      color: "black",
      opacity: 0.6,
    },
  },
  infoInput: {
    marginTop: theme.spacing(1),
    width: "100%",
    height: 32,
    paddingLeft: 14,
    boxSizing: "border-box",
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: "rgba(0, 0, 0, 0.27)",
    borderRadius: 3,
    "&:focus": {
      outlineColor: theme.palette.primary.main,
      outlineWidth: 2,
    },
  },
  textareaInput: {
    marginTop: theme.spacing(1),
    width: "100%",
    height: 82,
    boxSizing: "border-box",
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 10,
    resize: "none",
    borderColor: "rgba(0, 0, 0, 0.27)",
    borderRadius: 3,
    "&:focus": {
      outlineColor: theme.palette.primary.main,
      outlineWidth: 2,
    },
  },
  repairEstimateWrapper: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  repairEstimateDesc: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "15px",
    display: "inline-block",
  },
  addIcon: {
    fontSize: 34,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  removeIconWrapper: {
    marginLeft: 10,
    width: 30,
    height: 30,
  },
  removeIconRadio: {
    width: 26,
    height: 26,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D9D9D9",
  },
  removeIcon: {
    display: "inline",
    fontSize: 34,
    color: theme.palette.primary.contrastText,
    paddingBottom: 4,
    boxSizing: "border-box",
    cursor: "pointer",
    textAlign: "center",
  },
  repairEstimateTitleWrapper: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  repairEstimateTitle: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
    display: "inline-block",
  },
  repairEstimateItem: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  repairEstimateNo: {
    display: "inline-block",
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#979797",
    paddingBottom: 4,
  },
  descriptionInput: {
    fontFamily: "Helvetica",
    border: "none",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.system.darkGray,
    outline: "none",
    paddingBottom: 4,
    "&:focus": {
      borderBottomColor: theme.palette.primary.main,
    },
  },
  amountWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  moneyIcon: {
    fontSize: 14,
    color: theme.palette.system.darkGray,
    position: "absolute",
    top: 5,
  },
  amountInput: {
    width: "100%",
    fontFamily: "Helvetica",
    boxSizing: "border-box",
    paddingLeft: 14,
    textAlign: "right",
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  discountWrapper: {
    marginTop: 40,
    paddingRight: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  },
  discountItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 20,
  },
  discountLabel: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
    marginRight: 3,
  },
  discountContentWrapper: {
    position: "relative",
  },
  discountContent: {
    width: 100,
    fontFamily: "Helvetica",
    paddingLeft: 14,
    textAlign: "right",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 60,
    },
  },
  depositWrapper: {
    marginTop: 40,
    paddingRight: 40,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  totalWrapper: {
    marginTop: 40,
    paddingRight: 40,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  depositItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 20,
  },
  totalItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 20,
  },
  totalLabel: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "11px",
    marginRight: 3,
  },
  totalContentWrapper: {
    position: "relative",
  },
  totalIcon: {
    fontSize: 14,
    color: theme.palette.system.darkGray,
    position: "absolute",
    bottom: theme.spacing(1),
    left: 4,
  },
  totalContent: {
    width: 100,
    fontFamily: "Helvetica",
    paddingLeft: 14,
    textAlign: "right",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 60,
    },
  },
  mainRightWrapper: {
    width: "47%",
    display: "flex",
    flexDirection: "column",
  },
  inspectionTitleWrapper: {
    width: "100%",
    marginTop: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionTitle: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    fontWeight: 600,
    lineHeight: "14px",
  },
  applicableWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  checkered: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.system.darkGray,
  },
  applicable: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "13px",
  },
  inspectionContent: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "15px",
  },
  problemTypeWrapper: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  problemTypeItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 46,
    marginBottom: 8,
  },
  problemType: {
    marginLeft: 8,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "13px",
  },
  uploadButton: {
    marginTop: 22,
    width: 124,
    height: 20,
    borderRadius: 18,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: "Helvetica",
    backgroundColor: theme.palette.primary.contrastText,
    cursor: "pointer",
  },
  agreementTitle: {
    marginTop: 20,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "14px",
    fontWeight: 600,
    display: "block",
  },
  agreementContent: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "16px",
    display: "inline-block",
  },
  agreementContentColor: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  membershipTitle: {
    marginTop: 16,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "14px",
    fontWeight: 600,
    display: "block",
  },
  membershipItem: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
  },
  membershipText: {
    flex: 1,
    marginLeft: 8,
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.system.darkGray,
    lineHeight: "14px",
  },
  signatureTitleWrapper: {
    width: "100%",
    marginTop: 22,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  signatureTitle: {
    fontSize: 14,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "14px",
    fontWeight: 600,
    display: "inline-block",
  },
  clearButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 84,
    height: 22,
    borderRadius: 18,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: "Helvetica",
    backgroundColor: theme.palette.primary.contrastText,
    cursor: "pointer",
  },
  signatureWrapper: {
    marginTop: 10,
    width: "100%",
    height: 270,
  },
  createdByWrapper: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  repairedByWrapper: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  repairedByLabel: {
    width: "100%",
    marginBottom: 5,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "14px",
    fontWeight: 600,
    display: "inline-block",
    marginRight: 20,
  },
  createByInput: {
    color: "#000000",
    fontWeight: "bold",
    "&:after": {
      transform: "none",
      borderBottom: "1px solid #000000",
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: "14px",
    },
  },
  saveButtonWrapper: {
    width: "100%",
    marginTop: 36,
    marginBottom: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  saveButton: {
    width: 84,
    height: 24,
    backgroundColor: theme.palette.primary.main,
    borderWidth: 0,
    borderRadius: 18,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    fontFamily: "Helvetica",
    letterSpacing: 1,
    cursor: "pointer",
  },
  funcWrapper: {
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(8),
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px 
    ${theme.spacing(4)}px ${theme.spacing(3)}px `,
    boxSizing: "border-box",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      // width: 800
    },
  },
  comments: {
    marginTop: 0,
  },
  media: {
    height: 0,
    paddingTop: "53.18%",
    backgroundSize: "contain",
    filter: "brightness(0%)",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      paddingTop: "46.18%",
      marginTop: "8px",
    },
  },
  section: {
    marginTop: "22px",
  },
  error: {
    color: "red",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  topLabel: {
    right: 0,
    left: "auto",
    transform: "none",
    color: "#000000",
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: "9px",
    },
  },
  label: {
    width: 110,
    marginBottom: 5,
    fontFamily: "Helvetica",
    color: theme.palette.default.dark,
    lineHeight: "14px",
    fontWeight: 600,
    display: "inline-block",
    marginRight: 20,
  },
  sectionHeader: {
    color: "#00000080",
    marginBottom: "10px",
  },
  topHeader: {
    color: "#00000080",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: "10px",
      lineHeight: "normal",
    },
  },
  topFormHeader: {
    fontWeight: "bold",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      lineHeight: "1.2",
    },
  },
  topHeaderBox: {
    height: theme.spacing(6),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: "22px",
      lineHeight: 1,
      height: "42px",
    },
  },
  topButton: {
    alignSelf: "flex-start",
    position: "relative",
    top: "32%",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      top: 0,
    },
  },
  leftAlert: {
    fontSize: "14px",
    width: "80%",
    margin: "auto",
    color: "#0000007F",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      lineHeight: "1.2",
    },
  },
  toggleButton: {
    padding: 0,
  },
  divider: {
    backgroundColor: "#000000",
    height: "0.5px",
    marginBottom: "0.5rem",
    marginTop: "1rem",
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginTop: 0,
    },
  },
  signature: {
    marginTop: theme.spacing(1),
  },
  signatureCanvas: {
    height: "100%",
    width: "100%",
    border: "1px solid black",
  },
  buttonMargin: {
    margin: theme.spacing(1),
  },
  select: {
    width: 184,
  },
  margin: {
    marginLeft: 0,
  },
  soakingCheckbox: {
    fontSize: 14,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  warrantyExpiryDateWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  batteryHealthWrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  batteryHealthLabel: {
    display: "inline-block",
    width: 125,
    fontSize: 14,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: 14,
    },
  },
  batteryHealthInput: {
    width: "100%",
    fontFamily: "Helvetica",
    boxSizing: "border-box",
    paddingLeft: 3,
    textAlign: "left",
    paddingTop: 0,
    paddingBottom: 0,
  },
  muiGridRoot: {
    width: "48%",
  },
}));

export const SaveButton = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    border: "2px solid",
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    width: 84,
    height: 24,
    borderWidth: 0,
    borderRadius: 18,
    fontFamily: "Helvetica",
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: 84,
      height: 24,
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.main,
    },
    "&:focus": {
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.contrastText,
    },
  },
}))(Button);

export const CssTextField = withStyles((theme: Theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "& .MuiFormLabel-filled": {
      color: theme.palette.system.darkGray,
      opacity: 1,
    },
    "& .MuiInputLabel-outlined": {
      fontFamily: "Helvetica",
      color: theme.palette.system.darkGray,
      fontSize: 14,
      transform: "translate(14px, 9px) scale(1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      opacity: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.27)",
        opacity: 1,
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        fontFamily: "Helvetica",
        color: "black",
        fontSize: 14,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input":
      {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
  },
}))(TextField);
