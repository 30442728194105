import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { useStyles } from "./NavBar.style";
import classNames from "classnames";
import { globalContext } from "context/global";
import {
  ListItem,
  MenuItem,
  Menu,
  List,
  ListItemText,
} from "@material-ui/core";
import PermissionCheck from "common/PermissionCheck";

const Navigation: React.FC = React.memo(() => {
  const {
    setRoles,
    currentEmployeeId,
    employees,
    stores,
    currentStoreId,
    setCurrentStoreId,
  } = useContext(globalContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const history = useHistory();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    setCurrentStoreId(id);
    localStorage.setItem("storeId", id);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentEmployee = employees.find((emp) => emp.id === currentEmployeeId);

  return (
    <div className={classNames(classes.wrapper, "printHiddenArea")}>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            onClick={() => {
              history.replace("/transaction");
            }}
          >
            Elephone
          </Typography>

          <PermissionCheck requiredRoles={["ADMIN", "USER"]}>
            <Tooltip title="Store">
              <>
                <List style={{ paddingTop: "0", paddingBottom: "0" }}>
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="select a current store"
                    onClick={handleClickListItem}
                    style={{ paddingTop: "0", paddingBottom: "0" }}
                  >
                    <ListItemText
                      primary="Current Store"
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {stores.find((store) => store.id === currentStoreId)
                            ?.name ?? `No Store Selected`}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <Menu
                  id="lock-menu"
                  keepMounted
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {currentEmployee?.stores?.map((store) => {
                    return (
                      <MenuItem
                        key={store.id}
                        selected={store.id === currentStoreId}
                        onClick={(event) =>
                          handleMenuItemClick(event, store.id)
                        }
                      >
                        {store.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            </Tooltip>
          </PermissionCheck>

          <Tooltip title="logout">
            <IconButton
              color="inherit"
              onClick={() => {
                localStorage.removeItem("storeId");
                Auth.signOut();
                setRoles([]);
                history.replace("/signin");
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
});

export default Navigation;
