import React from "react";
import Input from "@material-ui/core/Input";
import { useStyles } from "./cell.style";

interface CellProps {
  value: string;
  inputValue: string;
  canSelect: boolean;
  isSelected: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Cell = React.forwardRef<HTMLDivElement, CellProps>(
  ({ value, inputValue, isSelected, canSelect, onChange, onClick }, ref) => {
    const classes = useStyles({ canSelect });
    return (
      <>
        {isSelected ? (
          <Input
            margin="dense"
            ref={canSelect ? ref : null}
            value={inputValue}
            onChange={onChange}
            autoFocus
          />
        ) : (
          <div className={classes.cellWrapper} onClick={onClick}>
            {value}
          </div>
        )}
      </>
    );
  }
);

export default Cell;
